import {useState} from "react";
import ReactModuleContainer from "@/Components/Modules/ReactModuleContainer.jsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import {faCopy} from "@fortawesome/free-regular-svg-icons";
import toast from "react-hot-toast";

export default function View({module}) {

    const [generatedPassword, setGeneratedPassword] = useState('£496kO1mCVo9')
    const [option, setOption] = useState({
        charLength: 4,
        difficulty: 0,
        uppercase: true,
        lowercase: true,
        number: true,
        symbol: true,
    })
    const copyOutputToClipboard = () => {
        if (!generatedPassword) {
            toast('Nothing to copy')
            return
        }

        if (navigator.clipboard) {
            navigator?.clipboard?.writeText(generatedPassword)
            toast.success('Copied to clipboard!', {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            })
        }
    }

    return <ReactModuleContainer module={module}>

        <div>
            <div
                className={'relative min-h-32 border-b-2 flex items-center text-4xl font-bold my-10 rounded bg-gray-200 dark:bg-neutral-800'}>
                <p className={'p-5 px-10 cursor-pointer'} onClick={copyOutputToClipboard}>
                    {generatedPassword}
                </p>
                <div className={'absolute flex text-lg gap-2 p-5 right-0 top-0'}>
                    <FontAwesomeIcon
                        className={'hover:shadow hover:-mt-1 transition-all cursor-pointer opacity-50 hover:opacity-100 p-4 bg-white rounded'}
                        icon={faCopy} onClick={copyOutputToClipboard}/>
                    <FontAwesomeIcon
                        className={'hover:shadow hover:-mt-1 transition-all cursor-pointer opacity-50 hover:opacity-100 p-4 bg-white rounded'}
                        icon={faRefresh}/>
                </div>
            </div>
        </div>

    </ReactModuleContainer>
}
