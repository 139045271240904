import React from 'react'
import Input from '@/Components/Form/Input.jsx'
import { useForm } from '@inertiajs/react'
import FormEditor from '@/Components/Editor/FormEditor.jsx'
import HR from '@/Components/HR.jsx'
import PrimaryButton from '@/Components/Form/Buttons/PrimaryButton.jsx'

export default function Form ({ category }) {

    const { post, data, setData, processing, errors, setErrors } = useForm({
        title: category.title,
        description: category.description,
    })

    const submit = (e) => {
        e.preventDefault()
        post('/' + category.slug + '/edit')
    }

    return <>
        <form onSubmit={submit}>

            <Input mainClassName={'my-10'}
                   label={'Title'}
                   defaultValue={category.title}
                   handleChange={e => setData('title', e.target.value)}/>

            <FormEditor label={'Description'} value={data.description}
                        onChange={e => setData('description', e)}/>
            <HR/>
            <PrimaryButton type={'submit'}>Save</PrimaryButton>
        </form>
    </>
}
