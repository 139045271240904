import React from 'react'
import { Link } from '@inertiajs/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

export default function NotFound ({ children }) {
    return <div className={'h-screen flex items-center justify-center select-none'}>
        <div className={'max-w-2xl w-full text-center dark:bg-transparent bg-white rounded-lg p-10'}>
            <h1 className={'font-black text-3xl'}>404 - Page Not Found</h1>
            <p className={'text-sm mb-5'}>
                It seems the page you're looking for is currently unavailable.
            </p>
            <Link href={'/'} className={'text-blue-400 hover:text-blue-500 transition-all'}>
                <FontAwesomeIcon icon={faHome} className={'mr-2'}/>
                Go Back to Homepage
            </Link>
        </div>
    </div>
}
