import React, { useCallback, useEffect, useState } from 'react'
import ModuleHead from '@/Components/Modules/ModuleHead.jsx'
import PrimaryButton from '@/Components/Form/Buttons/PrimaryButton.jsx'
import { useDropzone } from 'react-dropzone'
import {
  faCloudUpload,
  faFileCirclePlus,
  faFileDownload,
  faFileZipper,
  faSave,
  faSpinner,
  faTrash,
  faUndo,
} from '@fortawesome/free-solid-svg-icons'
import { PDFDocument } from 'pdf-lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@/Components/Form/Buttons/Button.jsx'

const Loader = () => {
  return <div>Loading...</div>
}

export default function View ({ className, children, module }) {

  const [readingMessage, setReadingMessage] = useState('')
  const [isReading, setIsReading] = useState(false)
  const [isReadingDocument, setIsReadingDocument] = useState(false)
  const [readSuccessful, setReadSuccessful] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  const [pdfFiles, setPDFFiles] = useState([])

  const onDrop = useCallback(acceptedFiles => {
    setIsReading(true)
    setReadingMessage('Reading File...')
    acceptedFiles.forEach(async accepted => {
      setIsReading(true)

      readFileAsync(accepted).then(async result => {
        setReadingMessage('File read successfully...')
        setIsReading(false)

        setReadingMessage('Processing file...')
        setIsReadingDocument(true)

        let reader = new FileReader()
        setReadingMessage('Creating PDF File')
        let pdfDocument = await PDFDocument.load(result)

        setReadingMessage('Processing PDF File')
        let savedDocument = await pdfDocument.save()

        setReadingMessage('Creating blob from PDF')
        let urlBlob = await pdfToUrlBlob(pdfDocument)

        let pdfBlankData = {
          title: pdfDocument.getTitle() ?? 'Untitled Document',
          doc: pdfDocument,
          page: [],
          blob: urlBlob,
        }

        setReadingMessage('Setting Files state...')
        setPDFFiles(prevState => [
          pdfBlankData, ...prevState])

        setReadingMessage('Done!')
        setIsReadingDocument(false)
      })

    })

    if (pdfFiles.length) {
      setReadSuccessful(true)
    }

  }, [])

  function readFileAsync (file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
      reader.readAsArrayBuffer(file)
    })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop, accept: { 'application/pdf': ['.pdf'] },
  })

  const pdfToUrlBlob = (pdf) => pdf?.save().then(doc => toUrlBlob(doc))

  const toUrlBlob = (data) => {
    const blob = new Blob([data], { type: 'application/pdf' })
    return URL.createObjectURL(blob)
  }

  const base64ToPDFDocument = async (data) => await PDFDocument.load(data)

  const getDocumentMetaData = (pdfDoc) => {
    return {
      title: pdfDoc.getTitle() || '',
      author: pdfDoc.getAuthor() || '',
      subject: pdfDoc.getSubject() || '',
      creator: pdfDoc.getCreator() || '',
      keywords: pdfDoc.getKeywords() || [],
      producer: pdfDoc.getProducer() || '',
      creation_date: pdfDoc.getCreationDate() || '',
      modification_date: pdfDoc.getModificationDate() || '',
    }
  }

  function copyPageFromDocToDoc (from, to) {

  }

  const createBlankPDF = async () => {
    let pdfDoc = await PDFDocument.create({ updateMetadata: true })

    pdfDoc.setTitle('New PDF File ' + Math.random())
    pdfDoc.setSubject('')
    pdfDoc.setAuthor('')
    pdfDoc.setCreator('')
    pdfDoc.setKeywords([])
    pdfDoc.setProducer('')

    let pdfBlankData = {
      title: pdfDoc.getTitle(),
      doc: pdfDoc,
      blob: await pdfToUrlBlob(pdfDoc),
    }

    setPDFFiles(prevState => [
      pdfBlankData, ...prevState])

    console.info('Blank PDF File created', pdfBlankData)
  }

  const CreateEmptyPDFDocumentGroup = () => {
    return <div
      className={'hover:bg-neutral-700 active:border border-lime-600 cursor-pointer select-none flex items-center justify-center text-4xl p-10 rounded shadow bg-neutral-800/50'}
      onClick={e => createBlankPDF()}>
      <FontAwesomeIcon
        className={''}
        icon={faFileCirclePlus}/>
      <div className={'ml-2'}>
        <span className={'block'}>New Blank PDF</span>
        <span className={'text-sm'}>Create a blank PDF Document</span>
      </div>
    </div>
  }
  const RenderSinglePage = ({ page }) => {
    return <div className={'shadow'}>
      <embed src={page} type={'application/pdf'}/>
    </div>
  }

  const MetaDataBlock = ({ title, children }) => <div className={'my-2'}><p className={'font-bold'}>{title}</p>{children}</div>

  const DocMetaData = ({ doc }) => {
    return <></>
    return <div className={'table table-auto text-xs'}>
      <MetaDataBlock title={'Title'}>{doc.getTitle() || ''}</MetaDataBlock>
      <MetaDataBlock title={'Subject'}>{doc.getSubject() || ''}</MetaDataBlock>
      <MetaDataBlock title={'Keywords'}>{doc.getKeywords() || ''}</MetaDataBlock>
      <MetaDataBlock title={'Author'}>{doc.getAuthor() || ''}</MetaDataBlock>
      <MetaDataBlock title={'Creator'}>{doc.getCreator() || ''}</MetaDataBlock>
      <MetaDataBlock title={'Creation date'}>{doc.getCreationDate()?.toString()}</MetaDataBlock>
      <MetaDataBlock title={'Modification date'}>{doc.getModificationDate()?.toString()}</MetaDataBlock>
    </div>
  }

  function CreatePagesFromPDFDocument (pdfDocument) {
    let pdfDocuments = []
    console.clear()
    let page = pdfDocument.getPage(1)
    console.log(page)

    return <div>prev</div>
  }

  const PDFPages = ({ pdfFile }) => {
    const [pages, setPages] = useState([])

    useEffect(e => {
      CreatePagesFromPDFDocument(pdfFile)
    }, [])
    const onPageDrag = (e) => {
      console.log(e)
    }

    return <div className={'flex flex-wrap gap-5 my-10'}>
      {pages.length && pages.map((page, i) => {
        return <div className={'w-40 aspect-[1/1.414] shadow text-xs bg-black text-white border dark:border-neutral-700 h-full'}
                    draggable={true} onDrag={onPageDrag}></div>
      })}
    </div>
  }
  const deletePDFDocumentFromView = (pdfFile) => pdfFiles.splice(pdfFiles.indexOf(pdfFile), 1)
    && setPDFFiles(r => [...pdfFiles])

  const PDFPreviewComponent = ({ file }) => {
    return <div
      className={'relative dark:border-neutral-700 border-2 dark:bg-neutral-800 my-5 p-5 rounded-lg shadow'}>
      {file.doc ? <><DocMetaData doc={file.doc}/></> : <></>}
      <div className={'w-full'}>
        <h3 className={'mb-5'}>{file.title}</h3>
        <small>Below are the list of pages in this PDF Document</small>

        <div className={'flex gap-2 flex-wrap'}>{file.doc && file.doc.getPages().map(page => {
          return <div className={'w-40 aspect-[1/1.414] h-full bg-black'}>
            {CreatePagesFromPDFDocument(file.doc)}
          </div>
        })}</div>
      </div>
      <div>
        <PrimaryButton icon={faSave} type={'button'}
                       ref={file.saveBtnRef}
                       onClick={e => file.doc.save().then(saved => {
                         e.target.disabled = true
                         setTimeout(() => e.target.disabled = false, 1000)
                       })}>
          Save
        </PrimaryButton>
        <Button icon={faFileDownload} type={'button'}>Download</Button>
        <PrimaryButton type={'button'} className={'bg-lime-800'} icon={faFileZipper}>Compress &amp; Download</PrimaryButton>
      </div>
      <div
        className={'absolute top-0 right-0 cursor-pointer opacity-20 hover:opacity-80 text-sm hover:text-lg active:opacity-100 transition-all'}
        onClick={e => deletePDFDocumentFromView(file)}>
        <FontAwesomeIcon className={'p-5 rounded-bl-lg dark:hover:bg-neutral-700'} icon={faTrash}/>
      </div>
    </div>
  }

  return <>
    <ModuleHead module={module} options={{}}/>
    <p className={'text-center p-5 text-neutral-600'}>{readingMessage}</p>
    <form>
      <PrimaryButton icon={faUndo}
                     type={'button'}
                     onClick={() => {
                       setPDFFiles([])
                     }}>Clear all files</PrimaryButton>
      <div
        className={'group/upload transition-all  flex items-center justify-center hover:border-lime-500 border-2 p-5 text-sm ' +
          'cursor-pointer dark:bg-lime-900/10 dark:hover:border-lime-500 dark:border dark:shadow-xl bg-white border-dashed rounded my-5 ' +
          'dark:border-green-800'}
        {...getRootProps()}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drop the files here ...</p> :
            <div className={'flex flex-wrap justify-center animate-pulse items-center'}>
              <FontAwesomeIcon icon={isReading || isReadingDocument ? faSpinner : faCloudUpload}
                               spin={isReading || isReadingDocument}
                               className={'group-hover/upload:xl:-mt-5 w-full lg:w-1/12 lg:text-5xl text-7xl mb-5 xl:mb-0 aspect-square ' +
                                 'dark:group-hover/upload:text-lime-400 group-hover/upload:text-lime-600 transition-all'}/>
              <p className={'w-full lg:w-11/12 pl-5'}>
                {isReading || isReadingDocument ? <>
                      <span className={'animate-pulse'}>
                        {readingMessage}
                      </span>
                  </>
                  : <>First, load a PDF file, then enter the pages you want to delete separated by comma (,).
                    <strong>Click here or Drag a PDF file here to proceed.</strong>
                    <small
                      className={'block mt-5 bg-lime-100/50 dark:bg-green-900/50 p-2 rounded border-dashed border-2 border-green-500/20'}>Selecting
                      a file here
                      does not upload it to our server, instead, the file will be loaded right into your
                      browser
                      without sending any data to the internet.</small></>}
              </p>
            </div>
        }
      </div>

      <div className={''}>
        {pdfFiles.length ? pdfFiles.map(file => <PDFPreviewComponent file={file}/>) : <>
          <p className={'select-none my-5 text-sm dark:text-neutral-600'}>You haven't loaded any PDF file, try to Create a PDF
            file or import
            one.</p>
        </>}
        <CreateEmptyPDFDocumentGroup/>
      </div>

    </form>
  </>
}