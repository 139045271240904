import ReactModuleContainer from '@/Components/Modules/ReactModuleContainer.jsx'
import { useEffect, useState } from 'react'
import Input from '@/Components/Form/Input.jsx'
import PrimaryButton from '@/Components/Form/Buttons/PrimaryButton.jsx'
import Field from '@/Components/Form/Field.jsx'
import ComboboxComponent from '@/Components/Form/ComboboxComponent.jsx'

export default function View ({ module }) {

    const [amount, setAmount] = useState(0)

    const currencies = [
        {
            label: 'USD',
            banknotes: [
                { label: '1USD', amount: 1 },
                { label: '5USD', amount: 5 },
                { label: '10USD', amount: 10 },
                { label: '20USD', amount: 20 },
                { label: '50USD', amount: 50 },
                { label: '100USD', amount: 100 },
            ],
            coins: [
                { label: '1¢', amount: 0.01 },
                { label: '5¢', amount: 0.05 },
                { label: '10¢', amount: 0.1 },
                { label: '25¢', amount: 0.25 },
                { label: '50¢', amount: 0.5 },
                { label: '$1', amount: 1 },
            ],
        },
        {
            label: 'EUR',
            banknotes: [
                { label: '5€', amount: 5 },
                { label: '10€', amount: 10 },
                { label: '20€', amount: 20 },
                { label: '50€', amount: 50 },
                { label: '100€', amount: 100 },
                { label: '200€', amount: 200 },
            ],
            coins: [
                { label: '1¢', amount: 0.01 },
                { label: '2¢', amount: 0.02 },
                { label: '5¢', amount: 0.05 },
                { label: '10¢', amount: 0.1 },
                { label: '20¢', amount: 0.2 },
                { label: '50¢', amount: 0.5 },
                { label: '1€', amount: 1 },
            ],
        },
        {
            label: 'GBP',
            banknotes: [
                { label: '5£', amount: 5 },
                { label: '10£', amount: 10 },
                { label: '20£', amount: 20 },
                { label: '50£', amount: 50 },
            ],
            coins: [
                { label: '1p', amount: 0.01 },
                { label: '2p', amount: 0.02 },
                { label: '5p', amount: 0.05 },
                { label: '10p', amount: 0.1 },
                { label: '20p', amount: 0.2 },
                { label: '50p', amount: 0.5 },
                { label: '1£', amount: 1 },
            ],
        },
        {
            label: 'JPY',
            banknotes: [
                { label: '1000¥', amount: 1000 },
                { label: '2000¥', amount: 2000 },
                { label: '5000¥', amount: 5000 },
                { label: '10000¥', amount: 10000 },
            ],

            coins: [
                { label: '1¥', amount: 1 },
                { label: '5¥', amount: 5 },
                { label: '10¥', amount: 10 },
                { label: '50¥', amount: 50 },
                { label: '100¥', amount: 100 },
            ],
        },
        {
            label: 'CAD',
            banknotes: [
                { label: '5$', amount: 5 },
                { label: '10$', amount: 10 },
                { label: '20$', amount: 20 },
                { label: '50$', amount: 50 },
                { label: '100$', amount: 100 },
            ],
            coins: [
                { label: '1¢', amount: 0.01 },
                { label: '5¢', amount: 0.05 },
                { label: '10¢', amount: 0.1 },
                { label: '25¢', amount: 0.25 },
                { label: '50¢', amount: 0.5 },
                { label: '$1', amount: 1 },
            ],
        },
        {
            label: 'LBP',
            banknotes: [
                { label: '1000 LBP', amount: 1000 },
                { label: '5000 LBP', amount: 5000 },
                { label: '10000 LBP', amount: 10000 },
                { label: '20000 LBP', amount: 20000 },
                { label: '50000 LBP', amount: 50000 },
                { label: '100000 LBP', amount: 100000 },
            ],
            coins: [
                { label: '100 LBP', amount: 100 },
                { label: '250 LBP', amount: 250 },
                { label: '500 LBP', amount: 500 },
            ],
        },
        {
            label: 'INR',
            banknotes: [
                { label: '10 INR', amount: 10 },
                { label: '20 INR', amount: 20 },
                { label: '50 INR', amount: 50 },
                { label: '100 INR', amount: 100 },
                { label: '200 INR', amount: 200 },
                { label: '500 INR', amount: 500 },
                { label: '2000 INR', amount: 2000 },
            ],
            coins: [
                { label: '1 INR', amount: 1 },
                { label: '2 INR', amount: 2 },
                { label: '5 INR', amount: 5 },
            ],
        },
    ]

    const [usedCurrency, setUsedCurrency] = useState(currencies[0])

    const submit = () => {
        console.log(usedCurrency)
    }

    useEffect(() => {
        submit()
    }, [usedCurrency, amount])

    return <ReactModuleContainer module={module}>

        <form onSubmit={submit}>

            <Field className={'flex items-center gap-x-2 justify-start'}>
                <Input type={'number'}
                       required={true}
                       mainClassName={'w-2/3'}
                       className={'outline bg-orange-500 text-white outline-1 outline-red-500'}
                       defaultValue={0}
                       label={'Enter the total amount you want to split'}
                       placeholder={'Amount'}
                       handleChange={(e) => setAmount(parseFloat(e.target.value))}/>
                <ComboboxComponent
                    label={'Select Currency'}
                    className={'w-1/3'}
                    placeholder={'Select Currency'}
                    options={currencies.map(c => ({ label: c.label, value: c.label }))}
                    selectedOption={usedCurrency}
                    onChange={e => {
                        setUsedCurrency(currencies.filter(f => f.label === e.value)[0])
                    }}/>
            </Field>

            <div className={''}>
                <table className={'w-full'}>
                    <thead>
                    <tr className={'text-sm select-none'}>
                        <th className={'w-40'}>Available amount</th>
                        <th>Banknote</th>
                        <th>Result</th>
                    </tr>
                    </thead>
                    {usedCurrency.banknotes.
                        sort((a, b) => a.amount < b.amount).
                        map(coin =>
                            <tr className={''}>
                                <td>
                                    <div className={'flex gap-x-2'}>
                                        <button type={'button'}>-</button>
                                        <Input placeholder={'-'} className={'mx-auto !w-10 text-center'}/>
                                        <button type={'button'}>+</button>
                                    </div>
                                </td>
                                <td><strong className={'select-none text-xs w-20'}>{coin.label}</strong></td>
                                <td>Results</td>
                            </tr>)
                    }</table>
            </div>

            <Field className={'justify-center flex mt-20'}>
                <PrimaryButton type={'submit'} className={'text-4xl rounded shadow py-5 !px-10'}>Split</PrimaryButton>
            </Field>
        </form>
    </ReactModuleContainer>
}
