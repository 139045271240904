import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'

export default function Button({
                                   type = 'submit',
                                   className = '',
                                   disabled,
                                   processing,
                                   children,
                                   onClick = () => {
                                   }, icon, iconClassName, title
                               }) {
    return (<button type={type}
                    className={`items-center px-4 py-2.5 font-semibold
    uppercase tracking-widest flex gap-x-2 hover:bg-blue-700 hover:text-white
    bg-neutral-200 text-neutral-700 focus:ring-1 focus:shadow-outline
    disabled:opacity-20 dark:hover:bg-blue-700
    transition ease-in-out rounded ${processing && 'opacity-40'} ` + className}
                    disabled={disabled || processing}
                    title={title}
                    onClick={onClick}>
        {processing
            ? <FontAwesomeIcon className={'mr-1'} icon={faSpinner} spin={true}/>
            : icon ? <FontAwesomeIcon className={(iconClassName ? ' ' + iconClassName : '')}
                                      icon={(processing && processingIcon) || icon}
                                      spin={processing}/> : <></>

        }

        {children.props?.children ? children : null}
    </button>)
}
