import HR from '@/Components/HR.jsx'

function ModuleInput ({ className, children }) {
  return <div className={'md:w-1/2 bg-red-600' + (className ? ' ' + className : '')}>
    {children}
  </div>
}

function ModuleOutput ({ className, children }) {
  return <div className={'md:w-1/2 bg-pink-500' + (className ? ' ' + className : '')}>
    {children}
  </div>
}

function ModuleLayout ({ children, module }) {

  return <div>
    <p dangerouslySetInnerHTML={{ __html: module.data.short_description }}/>
    <HR/>

    <p className={'text-xs'}
       dangerouslySetInnerHTML={{ __html: module.data.description }}/>
    <HR/>

    <div className={'my-5'}>
      {children}
    </div>

  </div>
}

export default ModuleLayout