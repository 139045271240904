import {Link, usePage} from '@inertiajs/react'
import HR from "@/Components/HR.jsx";

export default function Welcome({title, short_name, meta_description, slogan, categories = []}) {
    console.log(categories)
    return <div className={'flex content-center items-center justify-center flex-wrap'}>
        <div className={'content-end w-full py-20 flex items-center flex-wrap'}>
            <h1 className="xl:w-1/2 w-full drop-shadow dark:text-white text-3xl lg:text-6xl font-semibold">
                {short_name}
            </h1>
            <div className={'w-full xl:w-1/2'}>
                <p className="drop-shadow text-neutral-800 dark:text-neutral-200 font-semibold ml-2 py-2">{slogan}</p>
                <p className={'w-full max-w-xl text-sm'}>{meta_description}</p>
            </div>
        </div>

        <div className={'w-full'}><HR/></div>

        <div className={'w-full flex justify-center flex-wrap'}>
            <div className={'flex justify-center flex-wrap'}>{
                categories.data?.map(category =>
                    <div className={'w-full my-10'}>
                        <div className={'w-full rounded-xl p-5 -mx-5 w-1/4'}>
                            <h3 className={'font-black text-xl font-black mb-5'}>{category.title}</h3>
                            {category.modules.map(categoryModule =>
                                <Link href={categoryModule.link}
                                      className={'block my-1 bg-neutral-500/5 hover:bg-blue-300/20 transition-all rounded p-5'}>
                                    <h4 className={'font-bold text-lg'} href={categoryModule.link}>{categoryModule.title}</h4>
                                    <span className={'text-xs'} dangerouslySetInnerHTML={{__html: categoryModule.short_description}}/>
                                </Link>
                            )}
                        </div>
                    </div>
                )
            }</div>
        </div>
    </div>
}
