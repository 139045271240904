import {useRef, useState} from 'react'
import axios from 'axios'

export default function FormContainer({className, children, method = 'GET', action, enctype = 'multipart'}) {
    const [data, setData] = useState([])
    const [processing, setProcessing] = useState(0)
    const formRef = useRef()
    const submit = (e) => {
        e.preventDefault()
        setProcessing(1)
        axios.post(action).then(r => {
        }).catch(() => {
        }).finally(() => {
            setProcessing(0)
        })

    }
    return <>
        {processing}
        <form className={'' + (className ? ' ' + className : null)}
              ref={formRef}
              method={method}
              action={action}
              encType={enctype}
              onSubmit={submit}>
            {children}
        </form>
    </>
}
