import React, {useContext, useEffect, useState} from 'react'
import axios from 'axios'
import ReactPlayer from 'react-player'
import Input from '@/Components/Form/Input.jsx'
import {
    faCheckCircle,
    faCircle,
    faCircleDot,
    faDatabase,
    faGlobe,
    faPlay,
    faSave,
    faSpinner,
    faStop,
    faTrash,
    faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {AppContext} from '@/Context/AppContext.jsx'
import {Field, Label, Radio, RadioGroup, Transition} from '@headlessui/react'
import {Link, usePage} from '@inertiajs/react'
import PrimaryButton from '@/Components/Form/Buttons/PrimaryButton.jsx'
import Button from '@/Components/Form/Buttons/Button.jsx'
import ReactModuleContainer from '@/Components/Modules/ReactModuleContainer.jsx'

export default function View({module = {}}) {

    const {title, user} = usePage().props
    const {setDocumentTitle} = useContext(AppContext)

    const [agreement, setAgreement] = useState(localStorage.getItem('hls_agreement') === 'true')
    const [url, setURL] = useState('')
    const [runningURL, setRunningURL] = useState('')
    const [isRecording, setIsRecording] = useState(false)
    const [captured, setCaptured] = useState(null)
    const [savedLinks, setSavedLinks] = useState([])
    const [saveTitle, setSaveTitle] = useState('')
    const [saveType, setSaveType] = useState(null)
    const [saveResponse, setSaveResponse] = useState([])
    const [saving, setSaving] = useState(false)

    const [showLinksType, setShowLinksType] = useState(localStorage.getItem('hls_start') ?? 'public')

    const [showSaveFrom, setShowSaveForm] = useState(false)
    const [isLoadingSavedLinks, setIsLoadingSavedLinks] = useState(false)
    const [pagination, setPagination] = useState([])

    const submit = (e) => {
        e.preventDefault()
        setRunningURL(url)
    }

    const startRecording = () => {
        setIsRecording(!isRecording)
    }

    useEffect(() => {
        if (runningURL.length) {
            history.pushState({}, '', `?url=${runningURL}`)
        }

        //document.getElementById('player')?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, [runningURL])

    useEffect(() => {
        reloadSavedLinks()

        const urlParams = new URLSearchParams(window.location.search)
        const inputValue = urlParams.get('url')

        switch (localStorage.getItem('hls_start')) {
            case 'local': {
                setShowLinksType('local')
                break
            }
            default:
                setShowLinksType('public')
        }

        if (inputValue) {
            setRunningURL(inputValue)
            setURL(inputValue)
        }
    }, [])

    useEffect(() => {
        localStorage.setItem('hls_start', showLinksType)
    }, [showLinksType])
    const AgreementContainer = () => {
        return <div
            className={'fixed dark:bg-red-black/20 backdrop-blur-xl z-10 p-2 rounded-xl w-screen h-screen shadow top-0 left-0 flex items-center justify-center content-center'}>
            <div className={'max-w-xl text-sm p-5 mb-10 block'}>
                <p>

                    <h3><FontAwesomeIcon icon={faTriangleExclamation} className={'mr-1'}/> By accessing the Player, you agree to:</h3><br/>

                    <ul className={'list-disc list-inside'}>
                        <li>Only use the Player lawfully.</li>
                        <li>Not Use, share, or save copyrighted content without permission.</li>
                        <li>Respect the rights of others and avoid harmful content.</li>
                        <li>Understand the Player is "as is" and may have limitations.</li>
                    </ul>
                    <br/>
                    We are not responsible for misuse of the Player or copyright infringement.<br/><br/>
                </p>
                <div className={'my-10 flex gap-x-3'}>
                    <PrimaryButton type={'button'} onClick={() => {
                        localStorage.setItem('hls_agreement', 'true')
                        setAgreement(true)
                    }}>I Agree</PrimaryButton>

                    <PrimaryButton type={'button'}
                                   className={'dark:bg-red-900'}
                                   onClick={() => {
                                       localStorage.setItem('hls_agreement', 'false')
                                       setURL('')
                                       setRunningURL('')
                                       setAgreement(false)
                                   }}>I Do not Agree</PrimaryButton>
                </div>
            </div>
        </div>
    }
    const reloadSavedLinks = (page, term) => {
        setIsLoadingSavedLinks(true)
        if (showLinksType === 'local') {
            setSavedLinks(JSON.parse(localStorage.getItem('hls_stored')))
            setIsLoadingSavedLinks(false)
        } else {
            axios.get('/data/saved-hls-links?type=' + showLinksType
                + (page ? '&page=' + page : '')
                + (term ? '&term=' + term : ''),
            ).then(r => {
                setSavedLinks(r.data?.data?.data)
                setPagination(r.data.data.links)
            }).finally(() => setIsLoadingSavedLinks(false))
        }
    }

    useEffect(() => {
        reloadSavedLinks()
    }, [showLinksType])

    const saveLink = (e) => {
        e.preventDefault()
        setSaving(true)
        setSaveResponse([])

        if (saveType === 'local') {
            let prevSaved = JSON.parse(localStorage.getItem('hls_stored')) ?? []

            const newEntry = {
                title: saveTitle,
                url: runningURL,
                created_at: (new Date()).toISOString(),
                storage: 'local',
            }

            prevSaved.push(newEntry)

            localStorage.setItem('hls_stored', JSON.stringify(prevSaved))

            reloadSavedLinks()
            setSaving(false)
            setShowSaveForm(false)
            return
        }

        axios.put('data/saved-hls-links', {
            title: saveTitle,
            url: runningURL,
            type: saveType,
        }).then(r => {
            setSaveTitle('')
            setShowSaveForm(false)
        }).catch(e => {
            setSaveResponse(Object.values(e.response.data.errors))
        }).finally(() => {
            setSaving(false)
            reloadSavedLinks()
        })
    }

    const removeLink = (e, savedLink) => {
        e.stopPropagation()
        e.preventDefault()

        if (savedLink.storage === 'local') {
            const localSavedLinks = JSON.parse(localStorage.getItem('hls_stored'))
            delete localSavedLinks[savedLinks.indexOf(savedLink)]
            let updatedSavedLinks = localSavedLinks.filter(r => r.url)

            localStorage.setItem('hls_stored', JSON.stringify(updatedSavedLinks))
            reloadSavedLinks()
            return
        }

        axios.delete(`data/saved-hls-links/${savedLink.id}`).then(r => {
        }).finally(() => {
            reloadSavedLinks()
        })
    }

    const MediaSourceStorageInfoBlock = ({}) => {
        switch (showLinksType) {
            case 'local':
                return <>
                    Local links are stored inside your browser's local storage, no one can see them as they are not on the internet,
                    clearing browser data will clear this list.
                    <u className={'block cursor-pointer font-black'}
                       onClick={() => {
                           if (confirm('Are you sure you want to clear local storage entries?')) {
                               localStorage.removeItem('hls_stored')
                               reloadSavedLinks()
                           }
                       }}>{JSON.parse(localStorage.getItem('hls_stored') ?? '[]').length ? 'Clear local storage here' : null}</u>
                </>

            case 'public':
                return <>
                    Public links are stored on the site, with public visibility, everyone can see these links, and clearing the browser
                    history will not clear these links, unless you
                    manually delete them from the site.
                </>
        }
    }

    return <ReactModuleContainer module={module}>
        {runningURL && !agreement
            ? <AgreementContainer/>
            : <div>
                <form onSubmit={submit}>
                    <div className={'flex flex-wrap w-full my-10 items-center justify-center'}>

                        <Input
                            autoFocus={true}
                            mainClassName={'w-full px-1 md:w-9/12 mb-3 md:mb-0'}
                            id={'url'}
                            label={'URL'}
                            type={'url'}
                            name={'url'}
                            value={url}
                            placeholder={'Enter Video URL here...'}
                            handleChange={(e) => setURL(e.target.value)}
                        />

                        <div className={'w-full md:w-3/12 flex items-center justify-center flex-wrap'}>
                            <div className={'w-1/3 px-1'}>
                                <PrimaryButton
                                    icon={runningURL ? faStop : faPlay}
                                    onClick={() => {
                                        if (runningURL) {
                                            setRunningURL('')
                                            setDocumentTitle(title)
                                        } else {
                                            setRunningURL(url)
                                        }
                                    }}
                                    type={'button'}
                                    className={'w-full justify-center'}/>
                            </div>
                            <div className={'w-1/3 px-1'}>
                                <PrimaryButton
                                    icon={isRecording ? faCircle : faCircleDot}
                                    onClick={startRecording}
                                    disabled={true}
                                    title={isRecording ? 'Recording' : ''}
                                    type={'button'}
                                    className={'w-full justify-center ' +
                                        (isRecording ? ' !text-red-700' : '')}/>
                            </div>
                            <div className={'w-1/3 px-1'}>
                                <PrimaryButton
                                    icon={faSave}
                                    disabled={!runningURL}
                                    onClick={() => setShowSaveForm(!showSaveFrom)}
                                    type={'button'}
                                    className={'w-full justify-center dark:!bg-orange-800 ' +
                                        (showSaveFrom ? ' opacity-100 ' : 'opacity-80')}/>
                            </div>
                        </div>
                    </div>

                </form>
                {agreement ?
                    <strong className={'text-xs select-none'}><FontAwesomeIcon icon={faCheckCircle} className={'mr-1 text-lime-600'}/>You
                        have agreed to use this player lawfully,
                        you can <u onClick={() => {
                            localStorage.removeItem('hls_agreement')
                            setAgreement(false)
                            setRunningURL('')
                            setURL('')
                        }}>withdraw</u> here.</strong> : <></>}
                <Transition show={!!showSaveFrom}
                            enter={'duration-150'}
                            enterFrom={'opacity-0'}
                            enterTo={'opacity-100'}
                            leave={'duration-150'}
                            leaveFrom={'opacity-100'}
                            leaveTo={'opacity-0'}>
                    <form onSubmit={saveLink}
                          className={'p-8 dark:bg-black bg-neutral-100 border border-neutral-300 dark:border-neutral-700 my-10 rounded-2xl'}>
                        <h2 className={'select-none'}>Save Link</h2>
                        <p className={'m-0 text-xs select-none'}>You can save this link privately in your browser, on the site in public
                            directory.</p>
                        <div>
                            {saveResponse && saveResponse?.length ?
                                <div className={'rounded-lg my-4 dark:bg-neutral-900 bg-neutral-200 border-neutral-800 border p-5'}>{
                                    saveResponse.map(sr => <>{sr}</>)
                                }</div>
                                : <></>}
                            <div className={'flex flex-wrap items-center'}>
                                <div className={'w-full md:w-1/2 p-2'}>
                                    <Input handleChange={e => setSaveTitle(e.target.value)} required name={'title'}
                                           placeholder={'Link title'} label={'Give this link a title'}/>
                                </div>

                                <div className={'w-full md:w-1/2 p-2 pt-5 flex items-center justify-start flex-wrap'}>
                                    <div className={'w-full md:w-2/3 flex flex-wrap gap-x-2 text-xs'}>
                                        <span className={'w-full block text-xs mt-4'}>Where do you want to save this link to?</span>
                                        <RadioGroup
                                            onChange={e => setSaveType('local')}>
                                            <Field key={'local'} className="flex items-center gap-2">
                                                <Radio
                                                    value={'local'}
                                                    className={'group flex size-5 items-center justify-center rounded-full border bg-white data-[checked]:bg-blue-400'}>
                                                    <span className="invisible size-2 rounded-full bg-white group-data-[checked]:visible"/>
                                                </Radio>
                                                <Label>Local</Label>
                                            </Field>
                                            <Field key={'public'} className="flex items-center gap-2">
                                                <Radio
                                                    value={'public'}
                                                    className={'group flex size-5 items-center justify-center rounded-full border bg-white data-[checked]:bg-blue-400'}>
                                                    <span className="invisible size-2 rounded-full bg-white group-data-[checked]:visible"/>
                                                </Radio>
                                                <Label>Public</Label>
                                            </Field>
                                        </RadioGroup>
                                    </div>
                                    <div className={'w-full md:w-1/3 pt-2'}>
                                        <PrimaryButton
                                            disabled={!!saving}
                                            className={'w-full !justify-center'} icon={faSave}>Save</PrimaryButton>
                                        <Button
                                            disabled={!!saving}
                                            onClick={() => setShowSaveForm(false)}
                                            className={'w-full !justify-center'}>Close</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Transition>

                <hr className={'my-5 border-neutral-500'}/>
                <div className={'aspect-video bg-black rounded-xl overflow-hidden shadow'} id={'player'}>
                    <ReactPlayer
                        muted={true}
                        width={'100%'}
                        height={'100%'}
                        url={runningURL}
                        controls/>
                </div>

                <div className={'py-10'}>
                    <div className={'mb-5 select-none'}>
                        <h2>Saved Links</h2>
                        <small className={'max-w-2xl inline-block'}>
                            These are the links you, or someone else saved on our site, or a locally saved links in your personal browser.
                        </small>
                    </div>

                    <div className={'dark:bg-black border dark:border-transparent rounded-xl shadow p-5 text-xs mb-5'}>
                        <div className={'flex w-full flex-wrap items-start justify-between gap-y-2'}>
                            <div className={'flex md:w-7/12 py-2.5 w-full gap-x-1 h-min items-center'}>
                                <Button onClick={() => setShowLinksType('local')}
                                        icon={faDatabase}
                                        className={(showLinksType === 'local' ? '!bg-indigo-500 text-white' : '')}>Local Links</Button>

                                <Button onClick={() => setShowLinksType('public')}
                                        icon={faGlobe}
                                        className={(showLinksType === 'public' ? '!bg-indigo-500 text-white' : '')}>Public Links</Button>

                            </div>

                            {savedLinks?.length
                                ? <div className={'flex justify-end md:w-5/12 w-full'}>
                                    <Input placeholder={'Search here...'} className={'w-full'}
                                           handleChange={e => reloadSavedLinks(0, e.target.value)}/>
                                </div> : <></>}</div>

                        <span className={'select-none inline-block mt-2 w-full dark:text-neutral-500'}>
                            <MediaSourceStorageInfoBlock/>
                        </span>
                    </div>


                    {!isLoadingSavedLinks
                        ? <div>
                            {savedLinks?.length
                                ? <>{savedLinks.map(savedLink =>
                                    <div key={savedLink.url + savedLink.storage} className={'px-2 my-3 flex flex-wrap rounded shadow ' +
                                        'transition-all cursor-pointer odd:bg-black/5 dark:odd:bg-white/5 hover:shadow-lg ' +
                                        'hover:!bg-indigo-500 hover:text-white text-xs group ' +
                                        (savedLink.url === runningURL
                                            ? ' !bg-indigo-800 text-white'
                                            : '')}
                                         onClick={() => setRunningURL(savedLink.url) || (savedLink.title && setDocumentTitle('Playing: ' + savedLink.title))}>

                                        <div className={'w-11/12 py-4 px-3'}>
                                            <FontAwesomeIcon className={'mr-3 text-lg transition-all ' +
                                                (runningURL === savedLink.url ? ' ' : '!opacity-5')}
                                                             icon={faPlay}/>
                                            {savedLink.title ?? '-'}
                                        </div>

                                        <div
                                            className={'opacity-0 transition-all group-hover:opacity-100 w-1/12 flex justify-end items-center'}>
                                            {savedLink.can_delete || savedLink.storage === 'local' ? <Button
                                                    type={'button'}
                                                    icon={faTrash}
                                                    processing={!!saving}
                                                    disabled={!!saving}
                                                    onClick={(e) => removeLink(e, savedLink)}/>
                                                : <></>}
                                        </div>
                                    </div>)}
                                    {pagination.length > 3 ?
                                        <div className={'flex flex-wrap px-5 items-center justify-center my-10 gap-x-2'}>
                                            {pagination.map(page => <Link
                                                key={page.label}
                                                className={'py-3 font-bold transition-all px-5 inline-block text-center text-xs rounded ' +
                                                    (page.active ? ' dark:!bg-blue-500 !bg-blue-500 text-white ' : ' dark:text-white') +
                                                    (page.url ? ' hover:bg-blue-500/10 dark:text-white ' : ' opacity-20 text-xs')}
                                                as={'button'}
                                                disabled={!page.url}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    console.log(pagination)
                                                    const url = new URL(page.url)
                                                    const searchParams = new URLSearchParams(url.search)
                                                    reloadSavedLinks(searchParams.get('page'))
                                                }}><span dangerouslySetInnerHTML={{__html: page.label}}/>
                                            </Link>)}
                                        </div> : <></>}
                                </>
                                : <small className={'w-full text-center mx-auto block select-none'}>No
                                    saved <strong>{showLinksType}</strong> links found </small>
                            }
                        </div>
                        : <div className={'flex items-center justify-center content-center'}>
                            <FontAwesomeIcon icon={faSpinner} spin={true}/>
                        </div>}
                </div>
            </div>
        }

    </ReactModuleContainer>
}
