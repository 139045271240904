import React, {useContext} from 'react'
import ModuleHead from '@/Components/Modules/ModuleHead.jsx'
import ModuleFavoriteButton from '@/Components/ModuleFavoriteButton.jsx'
import {usePage} from '@inertiajs/react'
import {AppContext} from '@/Context/AppContext.jsx'
import HR from '@/Components/HR.jsx'
import {DiscussionEmbed} from "disqus-react";

export default function ReactModuleContainer({className, children, module, options = {}}) {
    const {auth} = usePage().props
    const appContext = useContext(AppContext)
    return <>
        <div className={'flex items-start -mt-8 mb-20'}>
            {auth.user ?
                <div className={'w-12 sticky top-28 mr-2 text-center'}>
                    <ModuleFavoriteButton showText={true} module={module} appContext={appContext} isFavorite={module.iFavorited}/>
                </div>
                : <></>
            }
            <div className={'w-full'}>
                <ModuleHead
                    module={module}
                    options={options}/>
            </div>
        </div>

        <div>
            {children}
        </div>
        <HR/>
        {/*<DiscussionEmbed shortname={'Discuss'} config={{*/}
        {/*    url: route('module.single',module.slug),*/}
        {/*    identifier: module.id,*/}
        {/*    title: module.title,*/}
        {/*}}/>*/}

        {module.description ? <article className={'mt-20 mb-10 border-t dark:border-t-neutral-800 pt-5'}>
            <p className={'text-sm'} dangerouslySetInnerHTML={{__html: module.description}}/>
        </article> : <></>}

        {module.usage ? <article className={'pt-5'}>
            <p className={'text-sm'} dangerouslySetInnerHTML={{__html: module.usage}}/>
        </article> : <></>}
    </>
}
