import React, {useEffect, useState} from 'react'
import TextArea from '@/Components/Form/TextArea'
import Input from '@/Components/Form/Input'
import HR from '@/Components/HR'
import AES from 'crypto-js/aes'
import Sha1 from 'crypto-js/sha1'
import SHA224 from 'crypto-js/sha224'
import SHA256 from 'crypto-js/sha256'
import Sha384 from 'crypto-js/sha384'
import Sha512 from 'crypto-js/sha512'
import SHA3 from 'crypto-js/sha3'
import Ripemd160 from 'crypto-js/ripemd160'
import HmacMd5 from 'crypto-js/hmac-md5'
import HmacSHA1 from 'crypto-js/hmac-sha1'
import HmacSHA3 from 'crypto-js/hmac-sha3'
import HmacSHA224 from 'crypto-js/hmac-sha224'
import HmacSHA256 from 'crypto-js/hmac-sha256'
import HmacSHARipe from 'crypto-js/hmac-ripemd160'
import HmacSHA384 from 'crypto-js/hmac-sha384'
import Rc4 from 'crypto-js/rc4'
import Rabbit from 'crypto-js/rabbit'
import RabbitLegacy from 'crypto-js/rabbit-legacy'
import Evpkdf from 'crypto-js/evpkdf'
import TripleDES from 'crypto-js/tripledes'
import ReactModuleContainer from "@/Components/Modules/ReactModuleContainer.jsx";

export default function EncoderDecoder({module}) {
    const [textInput, setTextInput] = useState('')
    const [reversibleInput, setReversibleInput] = useState({
        'Base64': '',
        'Binary': '',
        'Hex': '',
        'Morse Code': '',
        'AES': '',
        'Rabbit': '',
        'Rabbit Legacy': '',
        'Rc4': '',
        'TripleDES': '',
        'URL': '',
    })

    const [irreversibleInput, setIrreversibleInput] = useState({
        'evpkdf': '',
        'sha1': '',
        'sha256': '',
        'sha224': '',
        'sha384': '',
        'sha512': '',
        'hmac_md5': '',
        'hmac_sha1': '',
        'hmac_sha224': '',
        'hmac_sha256': '',
        'hmac_sha384': '',
        'hmac_sha3': '',
        'hmac_ripemd160': '',
    })

    const [encryptionKey, setEncryptionKey] = useState('')
    const [addWhiteSpaces, setAddWhiteSpaces] = useState(true)

    const descriptions = ({
        text: 'Plain text input for encoding or encryption.',
        reversible: {
            'Hex': 'Hexadecimal representation of the input text.',
            'Binary': 'Binary representation of the input text (0s and 1s).',
            'Base64': 'Base64 encoded version of the input text, commonly used for URL-safe data transmission.',
            'URL': 'The input text encoded for use within a URL.',
            'AES': 'Advanced Encryption Standard (AES) encrypted text using a provided encryption key.',
            'TripleDES': 'Triple DES encrypted text using a provided encryption key.',
            'Rabbit': 'Rabbit Stream Cipher encrypted text using a provided encryption key.',
            'Rabbit Legacy': 'Legacy version of Rabbit Stream Cipher encryption.',
            'Rc4': 'RC4 Stream Cipher encrypted text using a provided encryption key.',
            'Morse Code': 'Text converted to Morse code representation (series of dots and dashes).',
        },
        irreversible: {
            'sha1': 'SHA-1 hash of the input text (irreversible one-way hash function).',
            'sha224': 'SHA-224 hash of the input text (irreversible one-way hash function).',
            'sha256': 'SHA-256 hash of the input text (most common irreversible one-way hash function).',
            'sha384': 'SHA-384 hash of the input text (irreversible one-way hash function).',
            'sha512': 'SHA-512 hash of the input text (irreversible one-way hash function, stronger than SHA-256).',
            'hmac_md5': 'HMAC-MD5 hash of the input text with a provided encryption key (keyed hashing).',
            'hmac_sha1': 'HMAC-SHA1 hash of the input text with a provided encryption key (keyed hashing).',
            'hmac_sha224': 'HMAC-SHA224 hash of the input text with a provided encryption key (keyed hashing).',
            'hmac_sha256': 'HMAC-SHA256 hash of the input text with a provided encryption key (keyed hashing).',
            'hmac_sha384': 'HMAC-SHA384 hash of the input text with a provided encryption key (keyed hashing).',
            'hmac_sha3': 'HMAC-SHA3 hash of the input text with a provided encryption key (keyed hashing).',
            'hmac_ripemd160': 'HMAC-RIPEMD160 hash of the input text with a provided encryption key (keyed hashing).',
            'evpkdf': 'PBKDF2 key derivation function using the input text as a password (irreversible).',
        },
    })

    useEffect(() => {
        handleTextInput({target: {value: textInput}})
    }, [textInput])

    const handleTextInput = (event) => {
        setTextInput(event.target.value)
        const text = event.target.value
        const handleTextInput = async () => {

            try {
                setReversibleInput(prevData => ({
                        ...prevData,

                        'Binary': text
                            ? stringToBinary(text)
                            : '',

                        'Hex': text
                            ? stringToHex(text)
                            : '',

                        'Base64': text
                            ? stringToBase64(text)
                            : '',

                        'URL': text
                            ? encodeURIComponent(text)
                            : '',

                        'AES': text
                            ? AES.encrypt(text, encryptionKey).toString()
                            : '',

                        'TripleDES': text
                            ? TripleDES.encrypt(text, encryptionKey).toString()
                            : '',

                        'Rabbit': text
                            ? Rabbit.encrypt(text, encryptionKey).toString()
                            : '',

                        'Rabbit Legacy': text
                            ? RabbitLegacy.encrypt(text, encryptionKey).toString()
                            : '',

                        'Rc4': text
                            ? Rc4.encrypt(text, encryptionKey).toString()
                            : '',

                        'Morse Code': text
                            ? handleMorseCodeConversion(text)
                            : '',
                    }
                ))

                const [
                    sha1,
                    sha224,
                    sha256,
                    sha384,
                    sha512,
                    sha3,
                    ripe160,
                    hmac_md5,
                    hmac_sha1,
                    hmac_sha224,
                    hmac_sha256,
                    hmac_sha384,
                    hmac_sha3,
                    hmac_ripemd160,
                    evpkdf,

                ] = await Promise.all([
                    text ? Sha1(text).toString() : '',
                    text ? SHA224(text).toString() : '',
                    text ? SHA256(text).toString() : '',
                    text ? Sha384(text).toString() : '',
                    text ? Sha512(text).toString() : '',
                    text ? SHA3(text).toString() : '',
                    text ? Ripemd160(text).toString() : '',
                    text ? HmacMd5(text, encryptionKey).toString() : '',
                    text ? HmacSHA1(text, encryptionKey).toString() : '',
                    text ? HmacSHA224(text, encryptionKey).toString() : '',
                    text ? HmacSHA256(text, encryptionKey).toString() : '',
                    text ? HmacSHA384(text, encryptionKey).toString() : '',
                    text ? HmacSHA3(text, encryptionKey).toString() : '',
                    text ? HmacSHARipe(text, encryptionKey).toString() : '',
                    text ? Evpkdf(text, encryptionKey).toString() : '',
                ])

                // Update irreversible fields after calculations are complete
                setIrreversibleInput(prevData => ({
                    ...prevData,
                    sha1,
                    sha224,
                    sha256,
                    sha384,
                    sha512,
                    sha3,
                    ripe160,
                    hmac_md5,
                    hmac_sha1,
                    hmac_sha224,
                    hmac_sha256,
                    hmac_sha384,
                    hmac_sha3,
                    hmac_ripemd160,
                    evpkdf,
                }))
            } catch
                (error) {
                console.error('Error calculating hash:', error)
            }
        }
        handleTextInput().then(r => {
        })
    }

    const handleReversibleInputChange = async (event) => {
        const {name, value} = event.target

        switch (name) {
            case 'Base64': {
                try {
                    setTextInput(atob(value))
                } catch (e) {
                    setTextInput('Invalid from Base64 to String')
                }
                break
            }

            case 'Binary': {
                try {
                    setTextInput(binaryToString(value))
                } catch (e) {
                    setTextInput('Invalid from Binary to String ' + e.toString())
                }
                break
            }

            case 'Hex': {
                try {
                    setTextInput(hexToString(value, addWhiteSpaces))
                } catch (e) {
                    setTextInput('Invalid from Hex to String')
                }
                break
            }

            case 'Morse Code': {
                try {
                    setTextInput(morseCodeToText(value))
                } catch (e) {
                    setTextInput('Invalid from Morse Code to String')
                }
                break
            }

            case 'URL': {
                setTextInput(decodeURIComponent(value))
                break
            }

            default: {

            }
        }

        setReversibleInput((prevData) => {
            return {...prevData, [name]: value}
        })
    }

    useEffect(() => {

    }, [encryptionKey])

    useEffect(() => {

        }, [textInput, addWhiteSpaces],
    )

    const morseCodeMap = {
        'A': '.-', 'B': '-...', 'C': '-.-.', 'D': '-..', 'E': '.',
        'F': '..-.', 'G': '--.', 'H': '....', 'I': '..', 'J': '.---',
        'K': '-.-', 'L': '.-..', 'M': '--', 'N': '-.', 'O': '---',
        'P': '.--.', 'Q': '--.-', 'R': '.-.', 'S': '...', 'T': '-',
        'U': '..-', 'V': '...-', 'W': '.--', 'X': '-..-', 'Y': '.-.--',
        'Z': '--..', '1': '.----', '2': '..---', '3': '...--', '4': '....-',
        '5': '.....', '6': '-....', '7': '--...', '8': '---..', '9': '----.',
        '0': '-----', '.': '.-.-.-', ',': '--..--', ':': '...-.-.', '-': '-....-',
        ' ': '/',
    }

    const handleMorseCodeConversion = (text) => {
        let morseCodeString = ''
        for (let char of text.toUpperCase()) {
            morseCodeString += morseCodeMap[char] || ' ' // Add space for unknown characters
            morseCodeString += ' ' // Add space between characters
        }
        return morseCodeString.trim()
    }

    function morseCodeToText(morseCodeString) {
        // Invert the Morse code dictionary for lookup
        const morseCodeDictionary = Object.fromEntries(
            Object.entries(morseCodeMap).map(([key, value]) => [value, key]),
        )

        // Split the Morse code string into an array of characters (dots and dashes)
        const characters = morseCodeString.trim().split(' ')

        // Convert each Morse code character to its corresponding letter/number
        const decodedText = characters.reduce((acc, char) => {
            return acc + (morseCodeDictionary[char] || '')
        }, '')

        return decodedText
    }

    function stringToBase64(str) {
        try {
            const text = new TextEncoder()
            const utf8Bytes = text.encode(str)
            return btoa(String.fromCharCode(...utf8Bytes))
        } catch (err) {
            return 'err'
        }
    }

    function stringToBinary(str) {
        // Create a TextEncoder to handle UTF-8 encoding
        const encoder = new TextEncoder()

        // Convert the string to a Uint8Array (array of bytes)
        const bytes = encoder.encode(str)

        // Convert each byte to its 8-bit binary representation
        const binary = bytes.reduce((acc, byte) => {
            const binaryByte = byte.toString(2).padStart(8, '0')
            return acc + binaryByte
        }, '')

        return addWhiteSpaces ? addSpacesByCharacters(binary, 8) : binary
    }

    function binaryToString(binaryString) {
        // Remove any extra spaces from the binary string
        binaryString = binaryString.replace(/\s/g, '')

        // Split the binary string into an array of 8-bit binary sequences
        const byteArrays = binaryString.match(/(.{8})/g)

        // If the binary string length isn't divisible by 8, throw an error
        if (!byteArrays) {
            throw new Error('Invalid binary string length (not divisible by 8)')
        }

        // Convert each 8-bit binary sequence to a byte using parseInt with radix 2
        const bytes = byteArrays.map(byteArray => parseInt(byteArray, 2))

        // Create a TextDecoder to decode the byte array back to UTF-8 string
        const decoder = new TextDecoder()

        // Decode the byte array into a string
        const string = decoder.decode(new Uint8Array(bytes))

        // Add spaces every 8 characters if checkbox is checked
        if (addWhiteSpaces) {
            const chunks = []
            for (let i = 0; i < string.length; i += 8) {
                chunks.push(string.slice(i, i + 8))
            }
            return chunks.join(' ')
        }

        // Return string without spaces if checkbox is not checked
        return string
    }

    function stringToHex(str) {
        // Create a TextEncoder to handle UTF-8 encoding
        const encoder = new TextEncoder()

        // Convert the string to a Uint8Array
        const bytes = encoder.encode(str)

        // Convert each byte to its 2-digit hexadecimal representation with padding
        const hex = bytes.reduce((acc, byte) => {
            const hexByte = byte.toString(16).padStart(2, '0')
            return acc + hexByte + (addWhiteSpaces ? ' ' : '')
        }, '')

        return hex.toUpperCase()
    }

    function hexToString(hexString) {
        hexString = hexString.replace(/\s/g, '')
        if (hexString.length % 2 !== 0) {
            throw new Error('Invalid hex string length (not divisible by 2)')
        }
        const byteArrays = hexString.match(/(.{2})/g)
        const bytes = byteArrays.map(byteArray => parseInt(byteArray, 16))
        const decoder = new TextDecoder()
        return decoder.decode(new Uint8Array(bytes))
    }

    const RenderIrreversibleData = () => {
        return <div
            className={'font-mono dark:bg-neutral-800 dark:border-neutral-700 text-sm bg-neutral-50 border rounded px-5 py-2'}>
            {encryptionKey && <>
                <span className={'select-none'}>Encryption key: </span>{encryptionKey}
                <HR className={'mt-3'}/>
            </>}

            {Object.keys(irreversibleInput).filter(e => e).map((e) =>
                <div className={'flex flex-wrap gap-x-2 space-y-3 items-center justify-start'} key={e}>
                    <strong
                        className={'md:w-3/12 w-full md:mt-0 mt-5 inline-block select-none dark:text-neutral-400 text-neutral-600'}>{e.toUpperCase()}</strong>
                    <span className={'md:w-8/12 w-full break-all'}>{irreversibleInput[e]}</span>
                </div>,
            )}
        </div>
    }

    function addSpacesByCharacters(string, groupSize = 8) {
        const regex = new RegExp(`(.{${groupSize}})`, 'g')
        return string.match(regex)?.join(' ')
    }

    const encoderDecoderInput = (ed, value, description, onChange) => {
        return <div className={'w-full flex items-stretch content-between h-full flex-wrap'}>
            <div className={'mb-2'}>
                <label className={'select-none text-sm font-bold font-mono dark:text-neutral-300'}
                       htmlFor={ed}>{ed}</label>
                <blockquote
                    className={'border-l-4 block dark:border-neutral-800 pl-2 m-0 text-xs dark:text-neutral-400'}>
                    {description}
                </blockquote>
            </div>
            <div className={'w-full'}>
                <TextArea className={'font-mono !opacity-100'}
                          name={ed}
                          id={ed}
                          rows={7}
                          placeholder={ed + '\n\n(' + (description) + ')'}
                          handleChange={onChange}
                          value={value}></TextArea>
            </div>
        </div>
    }

    return <ReactModuleContainer module={module}>


        <div className={'flex flex-wrap my-5'}>
            {encoderDecoderInput('text', textInput, descriptions.text, handleTextInput)}
            {Object.keys(reversibleInput).map(ed => {
                    return <div className={'w-full py-10 md:w-1/3 lg:w-1/4 xl:w-1/5 p-2'}
                                key={ed}
                                id={ed.toLowerCase()}>
                        {encoderDecoderInput(ed, reversibleInput[ed], descriptions.reversible[ed], handleReversibleInputChange)}
                    </div>
                },
            )}
        </div>

        <div className={'flex justify-center'}>
            <label className={'space-x-2 text-xs flex pl-1'}>
                <input type={'checkbox'}
                       checked={addWhiteSpaces}
                       onChange={(e) => setAddWhiteSpaces(e.target.checked)}/>
                <span className={'select-none'}>Add whitespaces</span>
            </label>
        </div>

        <Input placeholder={'Encryption Key (disabled for now)'}
               disabled={true}
               handleChange={(e) => setEncryptionKey(e.target.value)}
               label={'Encryption Key to be used for the encryption / encoding, will be used for AES, and hmacs.'}/>

        <div className={'my-10'}>
            <small className={'select-none mb-2 block px-5'}>
                Fields here are irreversible, meaning you cannot decode these.
            </small>
            <RenderIrreversibleData/>
        </div>
    </ReactModuleContainer>
}
