import React from 'react'
import {Link, usePage} from '@inertiajs/react'

export default function Single({title, category}) {

    const {auth, meta_description} = usePage().props

    return <>

        {auth?.user ? <div className={'empty:hidden mb-10'}>
            <Link href={'/' + category.data.slug + '/edit'}>Edit</Link>
        </div> : <></>}

        {category.data.description
            ? <div className={'empty:hidden my-10 ps text-sm'}
                   dangerouslySetInnerHTML={{__html: category.data.description}}/>
            : <></>}

        <div className={'my-10 py-5 px-10 bg-white dark:bg-neutral-800/50 rounded-xl'}>
            <h2 className={'m-0 mb-5'}>Modules List</h2>
            <div className={''}>{category.data.modules?.map(m =>
                <Link href={m.link}
                      className={'block group w-full my-3 p-3 px-4 hover:shadow-lg hover:text-white hover:bg-blue-500 dark:hover:bg-white/5 cursor-pointer rounded'}>
                    <h4 className={'font-medium text-sm'}>{m.title}</h4>
                    <small className={'dark:group-hover:text-white dark:text-neutral-600'}
                           dangerouslySetInnerHTML={{__html: m.short_description}}/>
                </Link>)
            }</div>
        </div>
    </>
}
