import React from 'react'

import Button from '@/Components/Form/Buttons/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export default function PrimaryButton ({
  children, className, onClick, fn, disabled,
  icon, iconClassName, type, processing, processingText, processingIcon,
}) {
  return (
    <>
      <Button className={(className ? ' ' + className : '') +
        ' !bg-indigo-900/90 hover:!bg-indigo-500 text-white hover:shadow transition-all '
        + 'dark:disabled:opacity-10'
      }
              disabled={disabled || processing}
              processing={processing}
              onClick={onClick}
              type={type}
              icon={icon}
              iconClassName={iconClassName}
      >
        <span className={'inline-block'}>{children}</span>
      </Button>
    </>
  )
}
