import { useState } from 'react'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

export default function Select ({ className, disabled, name, _selected, multi = false, options = Object.values({}), fn }) {

    const [selected, setSelected] = useState(options[0])

    return (
        <div className="mx-auto h-screen">
            <Listbox value={selected} onChange={setSelected}>
                <ListboxButton
                    className={'relative flex items-center w-full rounded-lg bg-white/5 py-3 mt-1 pr-8 pl-5 text-left text-sm/6 text-white focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'}
                >
                    <div className={'w-full'}>{selected?.name || {name:'ok', value:'ok'}}</div>

                    <FontAwesomeIcon icon={faChevronDown} className={'right-0'}/>
                </ListboxButton>
                <ListboxOptions
                    anchor="bottom"
                    transition
                    className={
                        'w-[var(--button-width)] rounded-xl border border-white/5 bg-white dark:bg-black p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none ' +
                        'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'}
                >
                    {options.map((option) => (
                        <ListboxOption
                            key={option.name}
                            value={option}
                            className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
                        >

                            <div className="text-sm/6 text-white">{option.name}</div>
                        </ListboxOption>
                    ))}
                </ListboxOptions>
            </Listbox>
        </div>
    )
}
