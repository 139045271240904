import ModuleLayout from '@/Layouts/ModuleLayout.jsx'
import TextArea from '@/Components/Form/TextArea.jsx'
import { useRef, useState } from 'react'
import InputLabel from '@/Components/Form/InputLabel.jsx'
import Field from '@/Components/Form/Field.jsx'
import ReactModuleContainer from '@/Components/Modules/ReactModuleContainer.jsx'

export default function View ({ module }) {
    const [str, setStr] = useState('')

    const input = useRef()
    return <ReactModuleContainer module={module}>

        <div className={'md:flex flex-wrap items-stretch justify-between'}>
            <div className={'md:w-1/2'}>
                <h2>Input</h2>
                <Field>
                    <InputLabel>Enter Text Here</InputLabel>
                    <TextArea
                        ref={input}
                        handleChange={e => setStr(e.target.value)}
                        placeholder={'Enter content here to count its string length'}/>
                </Field>
            </div>
            <div className={'md:w-1/2 text-6xl'}>
                <div className={'text-center md:mt-20 py-1 font-black'}>{str.length}</div>
                <small className={'text-sm text-center block'}>Characters long</small>
            </div>
        </div>
    </ReactModuleContainer>
}
