import React, {useContext, useEffect, useReducer, useRef, useState} from 'react'
import DefaultTheme from 'tailwindcss/defaultTheme'
import PrimaryButton from '@/Components/Form/Buttons/PrimaryButton'
import OutputToolsButton from '@/Components/Modules/Elements/OutputToolsButton'
import Input from '@/Components/Form/Input'
import Bool from '@/Components/Form/Bool'
import CheckboxGroup from '@/Components/Form/CheckboxGroup'
import TextArea from '@/Components/Form/TextArea'
import Label from '@/Components/Form/Label'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAnglesRight, faSpinner} from '@fortawesome/free-solid-svg-icons'
import {faMinusSquare, faPlusSquare} from '@fortawesome/free-regular-svg-icons'
import Button from '@/Components/Form/Buttons/Button'
import ComboboxInput from '@/Components/Form/ComboboxInput'
import {AppContext} from '@/Context/AppContext'
import AdsComponent from '@/Components/AdsComponent'
import {useForm, usePage} from '@inertiajs/react'
import ModuleHead from '@/Components/Modules/ModuleHead.jsx'
import Skeleton from '@/Components/Skeleton.jsx'
import SkeletonInput from '@/Components/SkeletonInput.jsx'
import ComboboxComponent from '@/Components/Form/ComboboxComponent.jsx'
import axios from 'axios'

export default function Module({
                                   className, wrapperFn, name, fromObject, initialSize = 'full',
                                   defaultOptions = {
                                       showHead: true,
                                       showAds: true,
                                       showTitle: true,
                                       showShortDescription: true,
                                       showDescription: true,
                                       showBody: true,
                                       showOutput: true,
                                       showCopyToClipboard: true,
                                       closeOnBlur: false,
                                       openOnFocus: false,
                                   },
                                   _options,
                               },
) {

    const {auth} = usePage().props
    const {data, setData, post, processing, errors, progress, reset, setDefaults} = useForm({})
    const [options, setOptions] = useState({...defaultOptions})
    const [fontSize] = useReducer('')

    /** States **/
    const [module, setModule] = useState(null)
    const [inputs, setInputs] = useState(Object.entries([]))
    const [output, setOutput] = useState(['Press run...'])
    const [isProcessing, setIsProcessing] = useState(false)
    const [isLoading, setIsLoading] = useState(1)
    const [size, setSize] = useState('full')

    const {setDocumentTitle} = useContext(AppContext)

    useEffect(() => {
        Object.keys(_options ?? [])?.map(i => defaultOptions[i] = _options[i])

        setOptions(defaultOptions)

    }, [_options])

    useEffect(() => {
        if (fromObject) {
            setModule(fromObject)
            if (fromObject.inputs) {

                // Get Default output if set
                let DefaultOutput = fromObject?.code?.output?.output

                if (DefaultOutput.length) {
                    setOutput(prevState => Array.from(fromObject.code.output.output))
                }

                setModuleInputs(fromObject.inputs)
                setIsLoading(0)

            } else {
                console.warn('Module %s inputs not set', module.name)
                console.warn(fromObject.inputs)
            }
            return () => {
                setModule(null)
                setData(null)
                setInputs([])
                setOutput(null)
            }
        } else {
            getModuleFromApi()
        }
        setSize(initialSize)

        setDocumentTitle('asd')
    }, [])

    const handleOnBlur = () => {
    }
    const handleOnFocus = () => {
    }
    const onHandleChange = e => {

    }

    const getModuleFromApi = () => {
        setIsLoading(1)
        axios.post(route('module.json', name)).then(function (r) {
            setModuleInputs(r.data.inputs)
        }).catch(function (err) {

        }).finally(() => {
            setIsProcessing(false)
            setIsLoading(0)
        })
    }

    const setModuleInputs = (_in) => {
        _in = Object.values(_in)

        setInputs(_in)

        /*
         if _in.value is set use it as default for input.name
         if _in.type == checkbox || select there will be no .value for input root
         therefore jump in options array and get the checked ones and use them as defaults
         */
        let defaults = {}

        _in.map((input) => {

                // If input type is checkbox of select
                // These have multiple options
                if ((input.type === 'checkbox' || input.type === 'select')) {
                    let optionsObject = Object.values(input.options)

                    // Filter the options array for 'checked'===true
                    // Or pick the first item's value in the options object
                    defaults[input.name] =

                        optionsObject

                            // Only select items with checked === true
                            .filter(e => e.checked && e.value)

                            // Return the value of the found items
                            .map(e => e.value)

                            // Pick the first item from what's found
                            [0] || optionsObject[0].value

                } else {

                    // The item is not checkbox nor select
                    // get the value from the object itself without mapping, filtering
                    defaults[input.name] = input.value || ''
                }
            },
        )

        setData(defaults)

    }

    const filterBy = str => items.filter(
        item => new RegExp('^' + str.replace(/\*/g, '.*') + '$').test(item),
    )

    const submit = e => {

        e.preventDefault()
        setIsProcessing(true)

        setOutput('...')

        const formData = new FormData(e.target)

        if (e?.target?.input?.files?.length) {
        }

        // Delete the [name], [value] hidden input cause by headless UI
        formData.forEach((entry, key) =>
            key.endsWith('[label]') && formData.delete(key))

        formData.forEach((entry, key) =>
            key.endsWith('[name]') && formData.delete(key))

        formData.forEach((entry, key) => {
            if (key.endsWith('[value]')) {
                formData.delete(key)
                formData.append(key.replace('[value]', ''), entry)
            }
        })

        axios.post(route('module.single.run', module.slug), formData, {}).then(res => setOutput(Array.from(res.data.output))).catch(err => {
            if (err.response.data && err.response.data.errors) {
                setOutput(['<b class="text-red-500">' + err.message + '</b>', Object.values(err.response.data.errors).join('\n')])
            } else if (err.response.data && err.response.data.message) {
                setOutput([err.message, err.response.data.message])
            } else if (err.message) {
                setOutput([err.message])
            }
        }).finally(() => setIsProcessing(false))
    }

    function getOutput(e) {
        if (output.length) {
            output.join(',')
        }
        return output
    }

    const copyOutputToClipboard = () => {
        if (true || navigator.clipboard) {
            navigator?.clipboard?.writeText(output)
            alert('Copied to clipboard!')
        }
    }

    const toggleSize = (_size) => {
        setSize(size !== _size ? _size : initialSize)
    }

    const fn = {
        /* Basic Area */
        module: module,
        getModuleFromApi: getModuleFromApi,

        size: size,
        setSize: setSize,
        toggleSize: toggleSize,

        output: output,
        getOutput: getOutput,
        setOutput: setOutput,
        inputs: inputs,
        setInputs: setInputs,

        isProcessing: isProcessing,
        setIsProcessing: setIsProcessing,
        isLoading: isLoading,
        setIsLoading: setIsLoading,

        copyOutputToClipboard: copyOutputToClipboard,

        options: {...options},

        /* Form Area */
        form: {
            data: data,
            setData: setData,
            errors: errors,
            handleChange: onHandleChange,
            submit: submit,
            reset: reset,
            callme: function (e, data) {
                setData(e, data)
            },
        }, ...wrapperFn,
    }

    return <div
        className={'module-single relative transition-all duration-300 '
            + ' rounded-lg mt-10'
            + ' transition-all-old duration-old-75'
            + ' data-size-thumb:w-40 data-size-thumb:h-40 '
            + ' data-size-md:w-[100%] data-size-md:col-span-4 data-size-md:p-10'
            + ' data-size-sm:shadow-md data-size-sm:w-[50%]'
            + ' m-5'
            + ' data-size-full:pt-5 data-size-full:mx-0'
            + ' data-size-full:mt-20 mb-28 '

            + (className ? ' '
                + className : '')}

        data-size={size}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        tabIndex={-1}>

        {module ?
            <>
                <div className={'flex items-center -mt-24 mb-16'}>
                    <div className={'w-11/12'}>
                        {
                            options.showHead
                                ? <ModuleHead
                                    module={module}
                                    size={size} fn={fn} options={options}/>
                                : <></>
                        }
                    </div>
                </div>
                {options.showBody ? <ModuleBody module={module} fn={fn} options={options}/> : <></>}
            </>
            : <></>}


    </div>
}

function ModuleBody({className, fn, module, options = {}}) {

    const DefaultFontsKeys = Object.keys(DefaultTheme.fontSize)
    const defaultOutputFontSizeFromStorage = DefaultFontsKeys[localStorage.outputFontSize] || 'base'

    const [outputFontSize, setOutputFontSize] = useState(DefaultFontsKeys.indexOf(defaultOutputFontSizeFromStorage))

    const outputBoxRef = useRef()

    useEffect(e => {

        /* Delete localStorage entry if it's not valid */
        if (!defaultOutputFontSizeFromStorage) {
            localStorage.removeItem('outputFontSize')
        }

        /* add current font size to localStorage */
        if (outputFontSize) {
            localStorage.outputFontSize = outputFontSize
        }

    }, [outputFontSize])

    const getOutputFontSizeValue = () => {
        return 'text-' + (DefaultFontsKeys[outputFontSize] || 'base')
    }

    const increaseOutputFontSize = () => {
        setOutputFontSize(e => localStorage.outputFontSize =
            DefaultFontsKeys.indexOf(DefaultFontsKeys[outputFontSize + 1] || DefaultFontsKeys[outputFontSize]))
    }

    const decreaseOutputFontSize = () => {
        setOutputFontSize(e => localStorage.outputFontSize =
            DefaultFontsKeys.indexOf(DefaultFontsKeys[outputFontSize - 1] || DefaultFontsKeys[outputFontSize]))
    }

    return <div className={className}>

        {options.showAds ?
            <div className={'mb-5 overflow-hidden'}><AdsComponent dataAdSlot={'2443703777'}/></div> : <></>}

        {fn.isLoading ?
            <Skeleton active={true} className={'!p-0'} rootClassName={'!p-0'} rows={8}/>
            : <>
                {/* Hide Description from sm and thumb modules size */}
                {!Array.from(['sm', 'thumb']).filter(_size => fn.size == _size).length ?
                    <p className={'tb transition-all-old duration-old-100 text-sm data-size-thumb:text-[3px]'
                        + '  data-size-thumb:leading-[3px]'}
                       dangerouslySetInnerHTML={{__html: fn.module.display?.parsedText?.description}}
                       data-size={fn.size}/>
                    : <></>}
            </>
        }

        {/* An empty div to fetch all font tailwind sizes */}
        <div className="text-xs text-sm text-base text-lg
    text-xl text-2xl text-3xl text-4xl text-5xl
    text-6xl text-7xl text-8xl text-9xl"></div>

        <div className={'mt-10'}>
            <form onSubmit={fn.form.submit}>
                <div className={'flex flex-wrap items-start'}>

                    {/* ModuleInput */}
                    <div className={'relative w-full '
                        + (
                            module.code?.settings && module.code.settings.outputIsBlock == true ? '' : ' lg:w-7/12 2xl:w-4/12 '
                        )
                        + ' rounded-l-lg transition-all flex flex-wrap items-start content-start justify-start'}>

                        <div className={'w-full'}>{fn.isLoading
                            ? <>
                                <SkeletonInput active={true} className={'!w-full my-1'}/>
                            </>
                            : <>{fn.inputs && fn.inputs.map(function (input) {
                                return <ModuleInput key={input.name} input={input} fn={fn} disabled={fn.isProcessing}/>
                            })}</>}</div>

                        <div className={'mt-10 w-full'}>

                            {/*{ module.captcha === 1 && <Captcha/> }*/}

                            {fn.isLoading !== 1 &&
                                <div className="ant-btn-group w-full mt-0 z-10 relative">
                                    <PrimaryButton type={'submit'}
                                                   icon={faAnglesRight}
                                                   processing={fn.isProcessing}
                                                   processingText={'PleaseWait'}
                                                   processingIcon={faSpinner}
                                                   fn={fn}>
                                        {fn.isProcessing ? 'Running' : 'Run'}
                                    </PrimaryButton>
                                </div>}
                        </div>

                    </div>
                    {/* ُEnd ModuleInput */}

                    {/* OUTPUT */}
                    <div className={'relative rounded-none sm:rounded-xl '
                        + (
                            module.code?.settings && module.code.settings.outputIsBlock == true ?
                                '' : 'lg:rounded-xl module-output transition-all py-0 lg:px-5 mt-10 lg:mt-0 lg:w-5/12 2xl:w-8/12 '
                        )
                        + ' w-full min-h-[300px]'}>

                        <div className={'flex justify-between items-center sticky top-20 mt-7 '}>
                            <h2
                                className={'font-extra-bold text-2xl select-none px-5 py-2 rounded-lg dark:bg-neutral-800/50 dark:text-neutral-300 shadow'}>Output</h2>

                            <div className={(!fn.isLoading ? ' opacity-100' : 'opacity-0') + ' space-x-3 flex'}>

                                <OutputToolsButton disabled={!DefaultFontsKeys[outputFontSize - 1]}
                                                   icon={faMinusSquare} title={'Make text smaller'}
                                                   onClick={e => decreaseOutputFontSize()}/>

                                <OutputToolsButton disabled={!DefaultFontsKeys[outputFontSize + 1] || false}
                                                   icon={faPlusSquare} title={'Make text bigger'}
                                                   onClick={e => increaseOutputFontSize()}/>

                            </div>
                        </div>

                        <div
                            className={'transition-all mt-10 overflow-auto w-full font-mono break-all break-words whitespace-prewrap'
                                + ' ' + getOutputFontSizeValue()}
                            ref={outputBoxRef}>

                            {fn.output &&
                                <pre className={'whitespace-pre-wrap '}
                                     dangerouslySetInnerHTML={{__html: fn.output.join ? fn.output.join('\n') : fn.output}}/>}
                        </div>

                        {fn.isProcessing &&
                            <div className={' absolute top-0 z-9 backdrop-blur-sm left-0 transition-all w-full h-full'}>
                                <div
                                    className={'flex flex-wrap justify-center content-center w-full h-full items-center text-gray-400 drop-shadow'}>
                                    <div className={'font-thin font-xs mb-2'}>Loading...</div>
                                    <FontAwesomeIcon className={'w-full text-xl '} icon={faSpinner} pulse/>
                                </div>
                            </div>
                        }
                    </div>
                    { /* End Output */}
                </div>

            </form>

            {module.usage &&
                <div
                    className={''}>
                    <h2 className={'text-2xl font-extra-bold mb-5'}>Usage</h2>
                    <article className={'max-w-screen-xl overflow-hidden'}
                             dangerouslySetInnerHTML={{__html: module.display?.parsedText?.usage}}/>
                </div>
            }
        </div>

    </div>
}

function ModuleInput({
                         className, input, disabled, fn, options = {
        showLabel: true,
        showLabelDescription: true,
        showPlaceholder: true,
    },
                     }, key) {
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    })

    const renderInput = (input) => {
        switch (input.type) {
            case 'text' :
            case 'number' :
            case 'float':
            case 'date':
            case 'time':
            case 'datetime-local':
            case 'url':
            case 'file':

                if (input.type === 'float') {
                    input.type = 'number'
                }

                return <Input autoComplete={input.autocomplete ? input.name : ''}
                              step={input.step ?? null}
                              autoFocus={input.primary || false}
                              defaultValue={params[input.name] ? params[input.name] : input.value}
                              disabled={disabled}
                              handleChange={e => fn.form.handleChange(e)}
                              fn={fn}
                              input={input}
                              placeholder={input.placeholder}
                              required={input.required}
                              name={input.name}
                              type={input.type}
                              primary={input.primary ?? false}/>

            case 'button':
                return <div>
                    <Button className={'block ring-1 hover:ring-2 hover:ring-teal-400'} type={'button'}>
                        {input.label}
                    </Button>
                </div>

            case 'bool':
                return <Bool name={input.name} defaultValue={input.value}
                             placeholder={input.placeholder}
                             fn={fn} disabled={disabled}/>
            case 'checkbox':
                return <CheckboxGroup input={input}
                                      fn={fn}
                                      disabled={disabled}/>

            case 'combo':
                return <ComboboxInput name={input.name} options={input.options} disabled={disabled}/>

            case 'select':
                return <ComboboxComponent name={input.name}
                                          input={input}
                                          fn={fn}
                                          multi={input.multi}
                                          selectedOption={Object.values(input.options)[0]}
                                          disabled={disabled}
                                          options={Object.values(input.options)}/>

            case 'textarea':
                return <TextArea autoFocus={input.primary || false}
                                 autoComplete={input.autocomplete || ''}
                                 defaultValue={input.value}
                                 name={input.name}
                                 placeholder={input.placeholder}
                                 required={input.required ?? false}
                                 handleChange={(e) => fn.form.handleChange(e)}
                                 disabled={disabled}
                                 primary={input.primary ?? false}
                />

            default :
                return 'ModuleInput type not found: ' + input.type
        }
    }

    return <div className={'my-2 w-full'} key={key}>
        <Label
            className={'select-none mb-1 text-sm dark:text-neutral-400 mb-2 text-gray-500 uppercase'}>{input.label}</Label>
        {renderInput(input)}
        {/* Don't show label description if there are options */}
        {(input.type == 'combo' || input.type == 'select' || !input.options) &&
            <span className={'text-xs mt-1 dark:text-neutral-500 text-gray-800 my-2 mb-5 block'}
                  dangerouslySetInnerHTML={{__html: input.label_description}}
            />
        }
    </div>
}


