import {Link, usePage} from '@inertiajs/react'
import {faEdit} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {useContext, useState} from 'react'
import {AppContext} from '@/Context/AppContext.jsx'
import {faAngleDoubleRight, faEye} from '@fortawesome/free-solid-svg-icons'
import SkeletonInput from '@/Components/SkeletonInput.jsx'
import Skeleton from '@/Components/Skeleton.jsx'

export default function ModuleHead({className, module, size = 'md', options}) {
    const appContext = useContext(AppContext)
    const [isLoading, setIsLoading] = useState(false)

    return <div className={'' + (className ? className : '')}
                data-size={size}>

        {options.showTitle &&
            <>{isLoading ?
                <SkeletonInput className={'mb-3'}
                               ready={!isLoading}
                               type={'rect'}
                               style={{height: 30, width: 200}}
                               rows={1}
                               showLoadingAnimation={true}/>
                : <div className={(className ? className : '') + ' cursor-pointer'}>
                    <h1 className={'text-2xl md:text-5xl'}
                        data-size={size}>
                        {module.title}
                    </h1>
                </div>
            }</>
        }

        {isLoading
            ? <Skeleton paragraph={{rows: 0}} active={true}/>
            : <div className={'flex flex-wrap items-center'}>
                {module.short_description ? <>
                    <p className={'w-full tb text-sm mb-2 inline-block '
                        + 'data-size-sm:text-[0px] data-size-sm:leading-[5px] data-size-sm:opacity-0 '
                        + 'data-size-thumb:text-[0px] data-size-thumb:leading-[5]px data-size-thumb:opacity-0 '}
                       data-size={size}
                       dangerouslySetInnerHTML={{__html: module.display?.parsedText?.short_description}}/>
                </> : <></>}
                <ModuleHeadDetails module={module}/>
            </div>
        }
    </div>
}

function ModuleHeadDetails({className, module, size}) {
    const {user} = usePage().props.auth

    return <>

        <div className={(className ? className : '') + ' opacity-70 !-mx-1.5 '
            + ' select-none flex justify-start items-center data-size-sm:items-center'
            + ' text-xs font-light text-slate-500'}

             data-size={size}>

            <div className={'text-xs flex items-center justify-start ml-1 gap-x-5'}>
                {user && user?.email_verified_at
                    ? <div className={'flex items-center justify-start'}>
                        <ModuleHeadActionsItem
                            className={'text-xs mr-1 p-0.5 pr-1.5 rounded-sm hover:bg-gray-100'}
                            link={route('module.form', module.slug)}
                            icon={faEdit} text={'Manage'}
                        />
                        <form method={'post'} action={'/logout'} onSubmit={() => route('logout')}>
                            <button>Logout</button>
                        </form>
                    </div>
                    : <></>
                }


                <ModuleHeadActionsItem title={'Visited'} text={module.display.hits} icon={faEye}/>
                <ModuleHeadActionsItem title={'Ran'} text={module.display.ran} icon={faAngleDoubleRight}/>
            </div>

        </div>
    </>
}

function ModuleHeadActionsItem({className, title, icon, module, text, link, onClick, fn}) {

    return <div className={'text-xs select-none '
        + (className ? ' ' + className : '')}
                onClick={onClick}>

        {link ? <Link href={link}>
                <span>{title}</span>
                {
                    icon
                        ? <FontAwesomeIcon icon={icon} className={'mr-1'}/>
                        : <></>
                }
                <span>{text}</span>
            </Link> :
            <div className={'flex items-center justify-center gap-x-1'}>
                <span>{title}</span>
                {icon ? <FontAwesomeIcon icon={icon}/> : <></>}
                <strong>{text}</strong>
            </div>
        }
    </div>

}
