import { SliderPicker } from 'react-color'
import ModuleHead from '@/Components/Modules/ModuleHead.jsx'
import { useContext } from 'react'
import { AppContext } from '@/Context/AppContext.jsx'

export default function View ({
  className, children, module, defaultOptions = {
    showHead: true,
    showAds: true,
    showTitle: true,
    showShortDescription: true,
    showDescription: true,
    showBody: true,
    showOutput: true,
    showCopyToClipboard: true,
    closeOnBlur: false,
    openOnFocus: false,
  },
}) {
  const fn = useContext(AppContext)

  return <div>
    <ModuleHead module={module} options={defaultOptions} fn={fn}/>
    <SliderPicker/>
  </div>
}