import React, { useCallback, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faCloudUpload, faFileDownload, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import InputLabel from '@/Components/Form/InputLabel.jsx'
import Input from '@/Components/Form/Input.jsx'
import PrimaryButton from '@/Components/Form/Buttons/PrimaryButton.jsx'
import { useDropzone } from 'react-dropzone'
import { PDFDocument } from 'pdf-lib'

export default function LazyView () {
  const reader = new FileReader()
  const [isUpdating, setIsUpdating] = useState(false)

  const [loadedPDFDocument, setLoadedPDFDocument] = useState(null)
  const [pagesToRemove, setPageToRemove] = useState('')
  const [inverse, setInverse] = useState(false)
  const [preview, setPreview] = useState(null)

  const [fileSize, setFileSize] = useState(0)
  const [fileName, setFileName] = useState('')
  // todo validate the file is type pdf
  const onDrop = useCallback(acceptedFiles => {
    let mainFile = acceptedFiles[0]
    if (mainFile) {
      setIsUpdating(true)
      setFileSize(mainFile.size)
      setFileName(mainFile.name)
      readFile(mainFile)
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop, maxFiles: 1, multiple: false,
    accept: { 'application/pdf': ['.pdf'] },
    onDropAccepted: () => {
      setIsUpdating(true)
    },
  })

  const updatePreview = (data) => {
    const blob = new Blob([data], { type: 'application/pdf' })
    const docUrl = URL.createObjectURL(blob)
    setPreview(docUrl)
  }

  const readFile = (file) => {
    setPreview(null)
    setIsUpdating(true)
    reader.onload = (evt) => {

      PDFDocument.load(evt.target.result).then(loaded => {
        setLoadedPDFDocument(loaded)
        loaded.save().then(saved => {
          updatePreview(saved)
        }).finally(() => {
          setIsUpdating(false)
          loaded.flush()
        })

      })
    }
    reader.readAsDataURL(file)
  }

  const removePages = () => {
    setIsUpdating(true)
    let _pagesToRemove = pagesToRemove.split(',')
    try {
      if (inverse) {
        //let _pagesToKeep = _pagesToRemove
      } else {
        _pagesToRemove.forEach(i => {
          loadedPDFDocument.removePage(parseInt(i) - 1)
        })
      }

    } catch (ex) {
      setIsUpdating(false)
    }

    loadedPDFDocument.save().then(saved => {
      updatePreview(saved)
      setIsUpdating(false)
      saved.flush()
    })

  }
  const clearFile = () => {
    setPreview(null)
    setIsUpdating(false)
    if (loadedPDFDocument) {
      loadedPDFDocument?.flush()
      setLoadedPDFDocument(null)
    }

  }
  const downloadFinal = () => {
    setIsUpdating(true)
    const downloadLink = document.createElement('a')
    downloadLink.href = preview
    downloadLink.download = fileName
    downloadLink.target = '_blank'
    downloadLink.click()
    setTimeout(() => {
      setIsUpdating(false)
    }, 2000)
  }
  return <>
    <form className={''}>
      {!loadedPDFDocument ?
        <div
          className={'group/upload transition-all  flex items-center justify-center hover:border-lime-500 border-2 p-5 text-sm ' +
            'cursor-pointer dark:bg-lime-900/10 dark:hover:border-lime-500 dark:border dark:shadow-xl bg-white border-dashed rounded my-5 ' +
            'dark:border-green-800'}
          {...getRootProps()}>
          <input {...getInputProps()} />
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <div className={'flex flex-wrap justify-center animate-pulse items-center'}>
                <FontAwesomeIcon icon={isUpdating ? faSpinner : faCloudUpload}
                                 spin={isUpdating}
                                 className={'group-hover/upload:xl:-mt-5 w-full lg:w-1/12 lg:text-5xl text-7xl mb-5 xl:mb-0 aspect-square ' +
                                   'dark:group-hover/upload:text-lime-400 group-hover/upload:text-lime-600 transition-all'}/>
                <p className={'w-full lg:w-11/12 pl-5'}>
                  {isUpdating ? <>
                      <span className={'animate-pulse'}>
                        Your file is being processed, please wait!
                      </span>
                    </>
                    : <>First, load a PDF file, then enter the pages you want to delete separated by comma (,).
                      <strong>Click here or Drag a PDF file here to proceed.</strong>
                      <small
                        className={'block mt-5 bg-lime-100/50 dark:bg-green-900/50 p-2 rounded border-dashed border-2 border-green-500/20'}>Selecting
                        a file here
                        does not upload it to our server, instead, the file will be loaded right into your
                        browser
                        without sending any data to the internet.</small></>}
                </p>
              </div>
          }
        </div>

        : <div className={'flex w-full flex-wrap justify-between'}>
          <div className={'lg:w-1/2 w-full lg:pr-10 mb-10'}>

            <div className={'p-5 py-2 rounded bg-lime-50/30 border border-lime-500/30 text-sm space-x-2'}>
              {loadedPDFDocument && fileName && fileSize &&
                <div className={'hover:underline cursor-pointer flex justify-between'}
                     onClick={clearFile}>
                  <span className={'mr-1'}>
                    <FontAwesomeIcon icon={faCheckCircle} className={'text-lime-500 mr-1'}/>
                    File {fileName} was loaded in browser, of size {fileSize} bytes.</span>
                  <span><FontAwesomeIcon icon={faTrashCan} className={'mr-1'}/>Clear File</span>
                </div>
              }
            </div>

            <h2 className={'mb-2'}>Which Page do you want to delete?</h2>

            <InputLabel>Enter the Page(s) number which you want to be removed, separated by comma (,) for multiple
              pages.</InputLabel>

            <div className={'flex w-full items-stretch'}>
              <div className={'xl:w-8/12 w-7/12'}>
                <Input className={'w-full py-3'} handleChange={e => setPageToRemove(e.target.value)}
                       placeholder={'Page number separated by comma (e.g. 1,2,3....)'}/>
              </div>
              <PrimaryButton icon={faChevronRight} type={'button'}
                             className={'w-5/12 xl:w-4/12 whitespace-nowrap'}
                             onClick={removePages}>
                {inverse ? 'Keep only these' : 'Delete Pages'}
              </PrimaryButton>
            </div>

            <small>
              Fill the text field above with the page's numbers you want to delete, then press <strong>
              Delete Pages</strong>
            </small>
            {/*<Field>*/}
            {/*  <Checkbox onChange={e => setInverse(e.target.checked || false)}>*/}
            {/*    Inverse (keep the specified pages and delete the rest)*/}
            {/*  </Checkbox>*/}
            {/*</Field>*/}
            <div className={'mt-5'}>
              <PrimaryButton icon={faFileDownload} type={'button'} onClick={downloadFinal} processing={isUpdating}>
                Download Final
              </PrimaryButton>
            </div>
          </div>

          <div className={'lg:w-1/2 w-full pb-10 relative min-h-[700px]'}>

            {isUpdating &&
              <div
                className={'absolute z-10 dark:bg-black bg-white/20 w-full flex flex-wrap items-center justify-center content-center h-full backdrop-blur'}>
                <span className={'animate-pulse text-4xl w-full mb-2 text-center'}><FontAwesomeIcon
                  icon={faSpinner} spin={true}/></span>
                <span className={'block animate-pulse'}>Updating Preview</span>
              </div>}

            <embed src={preview ?? ''}
                   type={'application/pdf'}
                   width="100%"
                   height="100%"/>
          </div>
        </div>
      }
    </form>
  </>
}