import { faSpinner, faStar } from '@fortawesome/free-solid-svg-icons'
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { usePage } from '@inertiajs/react'

export default function ModuleFavoriteButton ({
    className,
    module,
    appContext,
    isFavorite,
    showText = false,
    text = 'Favorites',
}) {
    const { auth } = usePage().props
    const [isLoading, setIsLoading] = useState(false)
    const [isFaved, setIsFaved] = useState()

    useEffect(() => {
        setIsFaved(isFavorite)
    }, [])

    const handleFavAction = (e) => {
        e.stopPropagation()
        e.preventDefault()
        setIsLoading(true)
        window.axios.get(route('module.single.favorite', module.id)).then(res => {
            setIsFaved(() => res.data.iFavorited || false)
        }).finally(f => {
            setIsLoading(false)
        })
    }

    return auth?.user ? <button onClick={handleFavAction}
                                className={'text-black flex delay-150 items-center justify-center w-full'}>
        <FontAwesomeIcon
            className={'text-indigo-400 transition-all text-4xl' +
                (showText ? ' mr-2 ' : '') +
                (isFaved ? 'opacity-100 ' : 'opacity-50 ')}
            icon={isLoading ? faSpinner : (isFaved ? faStar : faStarEmpty)}/>
    </button> : <></>

}
