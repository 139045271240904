import { Fragment, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

export default function ComboboxInput ({
  className,
  name,
  options = [],
  multi = false,
  disabled = false,
}) {
  const [selectedOption, setSelectedOption] = useState(options.length ? options[0] : null)
  const [query, setQuery] = useState('')
  const filteredOptions = query === ''
    ? options
    : options.filter(option => option.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')))

  return <Combobox disabled={disabled} name={name} value={selectedOption} onChange={setSelectedOption} multiple={multi}>
    <div className="relative">
      <div
        className="relative w-full cursor-default overflow-hidden rounded text-left border dark:border-transparent
        focus-within:ring-4 focus-within:ring-indigo-400/60
        focus:outline-none focus-visible:ring-2 focus-visible:ring-red-300 focus-visible:ring-opacity-75 focus-visible:ring-offset-2
         focus-visible:ring-offset-teal-300 sm:text-sm">
        <Combobox.Input
          className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-2 dark:bg-neutral-700 disabled:dark:bg-neutral-800/40
           dark:text-neutral-200 disabled:dark:text-neutral-700/70"
          displayValue={(option) => option?.name || option}
          onChange={(event) => setQuery(event.target.value)}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2 group">
          <FontAwesomeIcon icon={faChevronDown}
                           className={'h-5 w-5 transition-all group-hover:text-teal-500' +
                             (disabled ? ' text-neutral-700' : ' text-neutral-400 ')}
                           aria-hidden="true"
          />
        </Combobox.Button>
      </div>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterLeave={() => setQuery('')}
      >
        <Combobox.Options
          className="absolute z-20 mt-1 -left-2 max-h-60 w-full overflow-auto rounded bg-white dark:bg-neutral-800 py-1 text-base shadow-lg
           ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {filteredOptions.length === 0 && query !== '' ? (
            <div className="relative cursor-default select-none py-2 px-4 text-gray-700 dark:text-neutral-600">
              Nothing found.
            </div>
          ) : (
            filteredOptions.map((option) => {
              return <Combobox.Option className={({ active }) =>
                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                  active ? 'bg-teal-600 text-white ' : 'text-gray-900 dark:text-neutral-400'
                }`
              } value={option.value} key={option.value}>
                {({ selected, active }) => (
                  <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {option.name}
                        </span>
                    {selected ? (
                      <span
                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                          active ? 'text-white' : 'text-teal-600'
                        }`}
                      >
                            <FontAwesomeIcon icon={faCheckCircle} className="h-5 w-5" aria-hidden="true"/>
                          </span>
                    ) : null}
                  </>
                )}

              </Combobox.Option>

            })
          )}
        </Combobox.Options>
      </Transition>
    </div>
  </Combobox>
}