import React, { useRef } from 'react'

export default function Input ({
    id,
    className,
    label,
    mainClassName,
    name,
    placeholder,
    defaultValue,
    autoComplete,
    autoFocus,
    required,
    disabled,
    handleChange,
    type = 'text',
    step,
    primary = false,
    min,
    max,
    onClick,
}) {

    const inputRef = useRef()
    const focusOn = () => {
        inputRef.current?.focus()
    }

    return <div className={'flex flex-col items-start' + (mainClassName ? ' ' + mainClassName : '')}>
        {label ? <label onClick={focusOn} className={'select-none text-sm mb-1'}>{label}</label> : <></>}
        <input autoComplete={autoComplete || ''}
               autoFocus={autoFocus}
               ref={inputRef}
               id={id}
               className={'w-full text-inherit border-neutral-200 rounded duration-100' +
                   ' dark:border-neutral-800 dark:bg-neutral-700 dark:text-neutral-300' + ' dark:focus-visible:ring-indigo-400/60' +
                   ' focus-visible:border-transparent focus-visible:ring-4 focus-visible:rounded focus-visible:outline-none' +
                   ' focus-visible:ring-indigo-600/70' +
                   ' disabled:cursor-default disabled:bg-neutral-100 disabled:text-neutral-300 disabled:active:ring-0 disabled:select-none' +
                   ' disabled:dark:bg-neutral-800/40 disabled:dark:text-neutral-700' +
                   ' placeholder:text-neutral-400 placeholder:font-thin' + (className ? ' ' + className : '') + (primary
                       ? ' ring-1 dark:ring-indigo-400  dark:text-neutral-200 dark:bg-neutral-500/50'
                       : ' dark:text-neutral-400 dark:bg-neutral-700/50')}
               defaultValue={defaultValue}
               disabled={disabled}
               name={name}
               step={step ?? null}
               placeholder={placeholder}
               required={required}
               max={max}
               onChange={(e) => handleChange(e)}
               type={type}
               onClick={onClick}
        />
    </div>
}
