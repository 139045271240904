import React from 'react'

import Button from '@/Components/Form/Buttons/Button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'

export default function SecondaryButton({
                                            children, className, onClick, disabled,
                                            icon, iconClassName, type, processing,
                                            title,
                                        }) {
    return (
        <>
            <Button className={(className ? ' ' + className : '') +
                ' bg-blue-100 dark:bg-neutral-800 dark:text-white hover:shadow transition-all '
                + 'dark:disabled:opacity-10'
            }
                    disabled={disabled || processing}
                    processing={processing}
                    onClick={onClick}
                    type={type}
                    icon={icon}
                    title={title}
                    iconClassName={iconClassName}
            >
                <span className={'inline-block'}>{children}</span>
            </Button>
        </>
    )
}
