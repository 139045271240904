import {Link, useForm} from '@inertiajs/react'
import Input from '@/Components/Form/Input.jsx'
import PrimaryButton from '@/Components/Form/Buttons/PrimaryButton.jsx'
import HR from '@/Components/HR.jsx'
import Field from '@/Components/Form/Field.jsx'
import FormEditor from '@/Components/Editor/FormEditor.jsx'
import ComboboxComponent from '@/Components/Form/ComboboxComponent.jsx'
import Checkbox from '@/Components/Form/Checkbox.jsx'
import {Button} from '@headlessui/react'
import DangerButton from '@/Components/Form/Buttons/DangerButton.jsx'
import SecondaryButton from '@/Components/Form/Buttons/SecondaryButton.jsx'

export default function ModuleForm({module, categories, robots =[]}) {

    const {
        data, setData, processing,
        post, wasSuccessful, recentlySuccessful,
        errors, setError, hasErrors, clearErrors,
        reset, isDirty,
    } = useForm({
        title: module.title,
        h1: module.h1,
        slug: module.slug,
        robots: module.robots,
        category_id: module.category?.id,
        description: module.description,
        short_description: module.short_description,
        usage: module.usage,
        published: module.published,
        is_active: module.is_active,
        captcha: module.captcha,
    })

    const submit = (e) => {
        e.preventDefault()
        post('/edit/' + module.slug, {
            onSuccess: (r) => {
                console.log(r)
            },
        })
    }

    return <>

        <form onSubmit={submit}>
            <div>
                {wasSuccessful ? <div className={'p-2 bg-lime-600 rounded-lg select-none px-4 text-sm my-10'}>Success</div> : <></>}
            </div>

            <Field className={'flex gap-x-2'}>

                <Input mainClassName={'w-1/2'} handleChange={e => setData('title', e.target.value)}
                       label={'Title'} defaultValue={data.title}/>

                <Input mainClassName={'w-1/2'} handleChange={e => setData('h1', e.target.value)}
                       label={'H1'} defaultValue={data.h1}/>

            </Field>

            <Field className={'flex gap-x-2'}>
                <Input mainClassName={'w-1/2'} handleChange={e => setData('slug', e.target.value)}
                       label={'Slug'} defaultValue={data.slug}/>

                <Input mainClassName={'w-1/2'} disabled={true} label={'name'} defaultValue={module.name}/>
            </Field>

            <HR/>
            <Field heading={'Category'}>
                <ComboboxComponent
                    onChange={e => setData('category_id', e.id)}
                    selectedOption={({
                        id: categories.filter(f => f.id === data.category_id)[0]?.id,
                        label: categories.filter(f => f.id === data.category_id)[0]?.title
                    })}
                    options={categories.map(o => ({id: o.id, label: o.title, value: o.id}))}/>
            </Field>

            <Field heading={'Robots'}>
                <ComboboxComponent
                    onChange={e => setData('robots', e.value)}
                    selectedOption={({
                        id: robots.filter(f => f === data.robots)[0]?.value,
                        label: robots.filter(f => f === data.robots)[0]?.name
                    })}
                    options={robots.map(o => ({id: o, label: o, value: o}))}/>
            </Field>


            <Field>
                <FormEditor
                    label={'Description'}
                    placeholder={'Description'}
                    value={data.description}
                    onChange={e => setData('description', e)}>{data.description}</FormEditor>
            </Field>

            <Field>
                <FormEditor
                    label={'Short Description'}
                    placeholder={'Short Description'}
                    value={data.short_description}
                    onChange={e => setData('short_description', e)}>{data.short_description}</FormEditor>
            </Field>

            <Field>
                <FormEditor
                    label={'Usage'}
                    value={data.usage} onChange={e => setData('usage', e)}>{data.usage}</FormEditor>
            </Field>

            <HR/>

            <div className={'flex items-center justify-center gap-x-4 text-xs my-10'}>
                <span className={'select-none'}>Options</span>
                <Checkbox checked={!!data.published}
                          name={'published'}
                          label={'Published'}
                          handleChange={e => setData('published', !!e.target.checked)}/>
                <Checkbox checked={!!data.is_active}
                          name={'is_active'}
                          label={'Active'}
                          handleChange={e => setData('is_active', !!e.target.checked)}/>
                <Checkbox checked={!!data.captcha}
                          name={'captcha'}
                          label={'Captcha'}
                          handleChange={e => setData('captcha', !!e.target.checked)}/>
            </div>

            <div className={'flex text-xs px-5 py-2 dark:bg-neutral-800/20 rounded-lg'}>
                <SecondaryButton type={'button'}>
                    <Link href={'/ext/' + module.name}>View</Link>
                </SecondaryButton>
                {isDirty ? <DangerButton onClick={() => reset()} type={'button'}>
                    Reset
                </DangerButton> : <></>}
                <PrimaryButton>Save</PrimaryButton>
            </div>
        </form>
    </>
}
