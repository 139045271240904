import React, {useEffect, useRef, useState} from 'react'
import axios from 'axios'
import {Link, usePage} from '@inertiajs/react'
import {useOutsideAlerter} from '@/Hooks/useOutsideAlerter'
import SidebarToggle from '@/Components/SidebarToggle'
import LeftSideButton from '@/Components/Sidebar/Elements/LeftSideButton'
import ThemeSwitcher from '@/Components/ThemeSwitcher'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBolt, faCircleXmark, faHome, faSearch, faSpinner} from '@fortawesome/free-solid-svg-icons'
import Footer from "@/Components/Footer.jsx";

export default function Sidebar({className, fn}) {
    const {short_name} = usePage()?.props
    const [isLoadingModulesList, setIsLoadingModulesList] = useState(false)
    const [modulesList, setModulesList] = useState([])
    const [categoriesList, setCategoriesList] = useState([])
    const [filteredModulesList, setFilteredModulesList] = useState([])
    const [focusedIndex, setFocusedIndex] = useState(-1)
    const [query, setQuery] = useState('')
    const sidebarRef = useRef()
    const sidebarHeaderRef = useRef()
    const searchInputRef = useRef()
    const url = window.location.origin + window.location.pathname

    useOutsideAlerter(sidebarRef, fn)

    const clearSearchInput = () => {
        searchInputRef.current.value = ''
        setQuery('')
    }

    const focusSearchInput = (e) => {
        if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.keyCode === 70) {
            fn.sidebar.setSidebarOpen(() => true)
            searchInputRef.current?.select()?.focus()
        }
    }

    const handleSearchKeyDown = e => {

        if (e.code === 'Escape') {
            clearSearchInput()
            if (!query) {
                fn.sidebar.setSidebarOpen(false)
            }
        }

        let _focusedIndex = focusedIndex

        if (e.key === 'ArrowUp' && filteredModulesList['Filtered']) {
            console.log(filteredModulesList)
            e.preventDefault()
            _focusedIndex = focusedIndex <= 0 ? filteredModulesList['Filtered'].length - 1 : focusedIndex - 1

        } else if (e.key === 'ArrowDown' && filteredModulesList['Filtered']) {
            e.preventDefault()
            _focusedIndex = focusedIndex + 1 >= filteredModulesList['Filtered'].length ? 0 : focusedIndex + 1
        }

        if (e.key === 'Enter') {
            e.preventDefault()
            document.getElementById('sidebar_nav_' + focusedIndex + '_filtered')?.click()
        }

        setFocusedIndex(_focusedIndex)

    }

    useEffect(() => {
        if (fn.sidebar.sidebarOpen) {
            getModulesList()
        }
        window.addEventListener('keydown', focusSearchInput)
        return () => window.removeEventListener('keydown', focusSearchInput)
    }, [fn.sidebar.sidebarOpen])

    const getModulesList = () => {

        if (modulesList.length) {
            return
        }

        setIsLoadingModulesList(true)

        axios.post('/api/modules/list').then(r => {
            setModulesList(r.data.data)
            setCategoriesList(r.data.data)
            setFilteredModulesList(r.data.data)
        }).finally(() => setIsLoadingModulesList(false))
    }

    useEffect(() => {

        setFocusedIndex(-1)

        if (!query) {
            return setFilteredModulesList(modulesList)
        }

        let filtered = [{
            title: 'Filtered',
            modules: categoriesList.flatMap(item =>
                item.modules.filter(module =>
                    module.title.toLowerCase().includes(query.toLowerCase())
                ))
        }]

        setFilteredModulesList(filteredModulesList)

        setFilteredModulesList(() => filtered)

    }, [query])

    return <div className={(className ? className : '')
        + ' bg-neutral-200/95 w-[380px] sm:w-[335px] h-screen duration-100 text-black dark:bg-neutral-900/95 z-[2000]'
        + ' flex fixed pt-1.5 left-0 z-50 dark:text-neutral-600'
        + ' data-sidebar-closed:w-16 data-sidebar-closed:h-16 data-sidebar-closed:bg-transparent data-sidebar-open:border-r-2 dark:border-r-white/5'}
                data-sidebar-open={fn.sidebar.sidebarOpen}
                ref={sidebarRef}>

        <div className="flex justify-start content-between h-full items-start flex-wrap">
            <div className={'w-full'} ref={sidebarHeaderRef}>
                <div className="flex dark:text-white">
                    <SidebarToggle
                        className={'md:text-lg ml-3 transition-all text-2xl aspect-square w-auto rounded-xl mx-1 mt-1.5 mr-3'
                            + (fn.sidebar.sidebarOpen ? ' ml-2.5 h-16 bg-blue-700/80 text-white '
                                + ' hover:!bg-blue-800 ' : ' text-blue-400 dark:text-white h-14')}
                        notShownClassName={'w-auto ' + (fn.sidebar.sidebarOpen ? ' h-16' : ' h-14')}
                        fn={fn} index={10}/>

                    <SearchInput className={fn.sidebar.sidebarOpen ? '' : 'hidden'}
                                 fn={fn}
                                 searchInputRef={searchInputRef}
                                 clearSearchInput={clearSearchInput}
                                 query={query} setQuery={setQuery}
                                 handleSearchKeyDown={handleSearchKeyDown}/>
                </div>
                <div className={'flex flex-wrap text-sm py-1 px-3 h-10 '
                    + (fn.sidebar.sidebarOpen ? ' ' : 'hidden')}>
                    <LeftSideButton icon={faHome} link={'/'} text={'Home'}/>
                    <ThemeSwitcher fn={fn} index={1} title={'Toggle Theme'}/>
                </div>
            </div>

            {fn.sidebar.sidebarOpen
                ? <div className={'w-full h-[calc(100dvh-210px)] overflow-auto'}
                       style={{scrollbarWidth: 'thin'}}>

                    {isLoadingModulesList
                        ? <div className={'flex items-center justify-center select-none opacity-50 h-full'}>
                            <FontAwesomeIcon icon={faSpinner} spin={true}/></div>
                        : <div className={'-mt-4'}>
                            {filteredModulesList.length ? filteredModulesList.map(cl =>
                                <div className={'text-sm p-5 mt-0 relative select-none group'}>
                                    <h3 className={'w-3 text-lg -ml-3 mr-7 sticky -top-3'}>
                                        {cl.link ? <Link href={cl.link}
                                                      className={(modulesList[cl.title]?.length > 2 ? 'mt-0 mb-20' : 'mb-10 ')
                                                          + ' w-full opacity-70 p-2 block text-sm -rotate-90 whitespace-nowrap select-none ' +
                                                          'text-neutral-600 dark:text-neutral-100 group-hover:text-indigo-600 ' +
                                                          'dark:group-hover:text-indigo-200 group-hover:opacity-100 duration-500'}
                                                      style={{direction: 'rtl'}}>{cl.title}</Link> :
                                            <span
                                                className={'w-full opacity-30 p-2 block text-sm -rotate-90 whitespace-nowrap select-none ' +
                                                    'text-neutral-600 font-light dark:text-neutral-600 group-hover:text-indigo-600 ' +
                                                    'dark:group-hover:text-indigo-200 group-hover:opacity-100 duration-500 mt-10'}>
                                                {cl.title}</span>}
                                    </h3>
                                    <div className={'-mt-16 ml-5'}> {cl.modules.map((category_modules, i) =>
                                        <Link
                                            className={'block py-1.5 px-3 rounded my-0.5 dark:text-neutral-400 text-neutral-600 ' +
                                                'hover:bg-indigo-800 hover:text-white dark:hover:text-white ' +
                                                (url === category_modules.link ? ' bg-gray-600 !text-white font-bold dark:text-indigo-500 ' +
                                                    'dark:visited:text-indigo-500 dark:bg-indigo-500/20 transition-all'
                                                    : '')
                                                + ((i === focusedIndex) ? ' focused' : ' ')}
                                            href={category_modules.link}>
                                            {
                                                category_modules.reactive
                                                    ? <FontAwesomeIcon
                                                        className={'mr-1 -mb-1 text-indigo-500 dark:text-white text-xs w-3 h-3 p-1 px-1 rounded'}
                                                        icon={faBolt} title={'Reactive'}>
                                                    </FontAwesomeIcon>
                                                    : <></>
                                            }
                                            <span>{category_modules.title}</span>
                                        </Link>)}
                                    </div>
                                </div>,
                            ) : <div
                                className={'text-xs select-none flex items-center justify-center h-[calc(100dvh-210px)]'}>No
                                items found</div>}
                        </div>}
                </div> : <></>}
            {/**/}
            {fn.sidebar.sidebarOpen ?
                <Footer/> : <></>
            }
        </div>
    </div>
}

function SearchInput({className, fn, query, setQuery, handleSearchKeyDown, searchInputRef, clearSearchInput}) {

    return <>
        <div className={'flex items-center mt-2 w-full relative  ' + (className ? ' ' + className : '')}>
            <div
                className={(query ? 'opacity-80 scale-125 ' : 'opacity-10') + ' transition-all text-right duration-old-300 absolute left-1 mt-1 ml-2 top-3.5 z-10 dark:text-white'}>
                <FontAwesomeIcon className={'text-xs'} icon={faSearch}/>
            </div>
            <input
                ref={searchInputRef}
                className={'w-full relative h-full rounded-none bg-transparent ring-0 focus:ring-0 transition-none border-0'
                    + ' text-xs font-thin py-3 pl-9 '
                    + ' focus-visible:outline-none dark:placeholder:text-neutral-400'}
                onChange={(e) => setQuery(e.target.value)}
                placeholder={(!fn.isMobile ? 'Ctrl + Shift + F / ' : '') + 'Search here...'}
                onKeyDown={handleSearchKeyDown}
                autoFocus={true}
                defaultValue={query}
                tabIndex={0}
            />
            {query ?
                <FontAwesomeIcon
                    className={'transition-all-old absolute outline-none cursor-pointer opacity-50 hover:opacity-100 focus:opacity-100  right-3 py-3 text-xs'}
                    icon={faCircleXmark}
                    onClick={clearSearchInput} tabIndex={-1}
                    onKeyPress={e => (e.code === 'Enter' || e.code === 'Space') && clearSearchInput()}
                /> : <></>
            }
        </div>
    </>
}
