import TextArea from '@/Components/Form/TextArea.jsx'
import InputLabel from '@/Components/Form/InputLabel.jsx'
import { jsonrepair } from 'jsonrepair'
import { useEffect, useState } from 'react'
import ModuleLayout from '@/Layouts/ModuleLayout.jsx'
import ReactModuleContainer from "@/Components/Modules/ReactModuleContainer.jsx";

export default function View ({ className, children, title, meta_description, usage, module }) {
  const [input, setInput] = useState('')
  const [repaired, setRepaired] = useState('')

  useEffect(e => {
    try {
      let repairedJson = jsonrepair(input)
      setRepaired(e => repairedJson)
    } catch (e) {
      setRepaired(p => e.message)

    }
  }, [input])

  return <ReactModuleContainer module={module} className={'pb-16'}>
    <div className={'md:flex flex-wrap items-stretch justify-between'}>
      <div className={'md:w-1/2 w-full'}>
        <InputLabel className={'dark:text-white'} forInput={'input'}>
          <h3>Input</h3>
        </InputLabel>
        <small className={'block mb-1'}>Enter malformed json string into the box below.</small>
        <TextArea handleChange={e => setInput(p => e.target.value)}
                  className={'bg-neutral-100 focus:bg-white dark:focus:bg-neutral-700 transition-all dark:'}
                  name={'input'} id={'input'}
                  placeholder={'Input'}/>
      </div>
      <div className={'md:w-1/2 w-full'}>
        <h3 className={'mb-5'}>Repaired JSON</h3>
        <div className={'rounded dark:bg-neutral-800 bg-neutral-50 w-full p-5'}>
          {repaired}
        </div>
      </div>
    </div>
    <hr className={'my-5 mt-10 py-2 border-neutral-400 dark:border-neutral-800'}/>
    <p dangerouslySetInnerHTML={{ __html: module.usage }}/>
  </ReactModuleContainer>
}
