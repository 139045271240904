import Wrapper from '@/Layouts/Wrapper.jsx'
import { useEffect, useState } from 'react'
import Field from '@/Components/Form/Field'
import Label from '@/Components/Form/Label'
import Input from '@/Components/Form/Input'
import FormContainer from '@/Components/Form/FormContainer'
import TextArea from '@/Components/Form/TextArea'
import PrimaryButton from '@/Components/Form/Buttons/PrimaryButton'
import { faPenNib } from '@fortawesome/free-solid-svg-icons'
import Rating from '@/Components/Rating'

export default function Form ({ className, children, ...props }) {
  const [userRequests, setUserRequests] = useState([])

  const userRequestsLayout = () => {
  }

  useEffect(() => {
    setUserRequests(props.userRequests)
  }, [])

  return <Wrapper {...props}>

    <FormContainer action={'/requests/submit'} className={'m-2 mb-5'} method={'POST'}>
      {({ processing }) => {
        {console.log(processing)}
        <div>{processing}</div>
      }

      }
      <Field>
        <Label>Your E-mail</Label>
        <Input type={'text'} name={'email'} placeholder={'Enter Your E-Mail'}/>
      </Field>
      <Field>
        <Label>Request Content</Label>
        <TextArea name={'content'} placeholder={'Enter your request message here...'}></TextArea>
      </Field>

      <PrimaryButton className={'text-xs'} iconClassName={'mr-2.5'} icon={faPenNib}>Submit</PrimaryButton>

    </FormContainer>

    {
      userRequests.map((userRequest) => {
        return <div key={userRequest.created_at}
                    className={'flex justify-start items-center p-5 border shadow-sm '
                      + 'dark:border-teal-900 m-2 rounded text-sm'}>
          <div className={'w-10 p-2 mr-4 text-center font-bold'}>
            <Rating item={userRequest} canVote={props.can.vote}/>
          </div>


          <div className={'w-11/12 px-2'}>
            <div className={'mb-5 font-extra-bold text-neutral-500 flex justify-between content-between align-center'}>
              <div className={''}>{userRequest.email}</div>
              <div className={'space-x-2 font-light text-xs'}>
                <span className={'badge inline-block bg-indigo-200 px-2 py-0.5 rounded'}>{userRequest.display.approved}</span>
                <span>{userRequest.display.solved}</span>
                <span>{userRequest.display.created_at}</span>
              </div>
            </div>
            <p className={'text-sm'}>{userRequest.content}</p>
          </div>
        </div>
      })
    }

  </Wrapper>
}