import InputLabel from '@/Components/Form/InputLabel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef, useState } from 'react'
import Input from '@/Components/Form/Input'
import { usePage } from '@inertiajs/react'
import TextArea from '@/Components/Form/TextArea'
import QRCodeStyling from 'qr-code-styling'
import PrimaryButton from '@/Components/Form/Buttons/PrimaryButton'
import { TwitterPicker } from 'react-color'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { Field, Label, Radio, RadioGroup } from '@headlessui/react'
import ReactModuleContainer from '@/Components/Modules/ReactModuleContainer.jsx'

const qrCode = new QRCodeStyling({
    width: 1000,
    height: 1000,
    extension: 'png',
    backgroundOptions: {
        color: '#fff',
        round: .1,
    },
    margin: 40,

    dotsOptions: {
        color: '#4267b2',
    },
    imageOptions: {
        crossOrigin: 'anonymous',
        margin: 30,
    },
})
export default function View ({ className, children, module }) {
    const TYPE_TEXT = 0

    const page = usePage()

    const [input, setInput] = useState('https://qr-code-styling.com')
    const [roundness, setRoundness] = useState(.1)
    const [dotsOptionsType, setDotsOptionsType] = useState('rounded')
    const [backgroundColor, setBackgroundColor] = useState({ hex: '#d9e3f0' })
    const [dotsColor, setDotsColor] = useState({ hex: '#1c2228' })
    const [cornerColor, setCornerColor] = useState({ hex: '#545760' })
    const [cornerDotsColor, setCornerDotsColor] = useState({ hex: '#2e3548' })
    const ref = useRef(null)

    const [selectedType, setSelectedType] = useState(TYPE_TEXT)
    const [isProcessing, setIsProcessing] = useState(false)
    const TypeSwitch = ({ children, icon, title, type }) => {
        return <div className={'w-full lg:w-1/3 p-0.5 '} onClick={e => setSelectedType(type)}>
            <div className={'flex flex-wrap h-full border border-transparent rounded-sm p-2 ' +
                'cursor-pointer duration-500 ' +
                (type === selectedType
                    ? ' bg-indigo-500 text-white hover:bg-indigo-900 '
                    : '') +
                'hover:border-indigo-300 hover:bg-indigo-50/20 hover:shadow-md '
            }>
                <div className={'w-3/12 px-2 flex items-center justify-center'}>
                    <FontAwesomeIcon icon={icon} className={
                        'text-4xl text-indigo-500 transition-all ' +
                        (selectedType === type ? 'text-white' : '')}/>
                </div>
                <div className={'w-9/12 px-2'}>
                    <h3 className={'m-0 p-0'}>{title}</h3>
                    <p className={'text-sm'}>{children}</p>
                </div>
            </div>
        </div>
    }
    useEffect(() => {
        qrCode.append(ref.current)
        qrCode.update({
            backgroundOptions: {
                round: roundness,
                color: backgroundColor?.hex,
            },
            dotsOptions: {
                type: dotsOptionsType,
                color: dotsColor?.hex,
            },
            cornersDotOptions: {
                color: cornerDotsColor?.hex,
            },
            cornersSquareOptions: {
                color: cornerColor?.hex,
            },
        })
    }, [
        roundness,
        dotsOptionsType,
        backgroundColor,
        dotsColor,
        cornerColor,
        cornerDotsColor,
    ])

    const dotTypes = [
        'Rounded',
        'Dots',
        'Classy Rounded',
        'Square',
        'Extra Rounded',
    ]
    useEffect(() => {
        qrCode.update({
            data: input,
        })
    }, [input])


    const submit = (e) => {
        e.preventDefault()
    }

    return <ReactModuleContainer module={module}>
        <div className={'flex flex-wrap items-stretch justify-stretch pb-20'}>
            <Field className={'w-full lg:w-2/4 px-2'}>
                <form className={'flex flex-wrap'} onSubmit={submit}>
                    <div className={'w-full'}>
                        <div className={'my-10 w-full'}>
                            {selectedType === TYPE_TEXT
                                ? <>
                                    <Field className={'w-full'}>
                                        <InputLabel>Text Content</InputLabel>
                                        <TextArea handleChange={(e) => setInput(e.target.value)}
                                                  required={true}
                                                  primary={true}
                                                  name={'input'} dsisabled={isProcessing}
                                                  placeholder={'Enter Content to be created into a QR Code here...'}/>
                                    </Field>
                                    <Field>
                                        <hr className={'dark:border-neutral-800 my-5'}/>
                                        <Field heading={'Roundness'}>
                                            <InputLabel>Roundness (Enter roundness value from 0 to 1, 0 is sharp square, 1 is full
                                                circle)</InputLabel>
                                            <Input placeholder={'Roundness'} type={'number'}
                                                   defaultValue={0.1} step={0.01} max={1}
                                                   handleChange={e => setRoundness(e.target.value)}
                                            />
                                        </Field>
                                        <hr className={'dark:border-neutral-800 my-5'}/>
                                        <Field heading={'Dots Options'}>
                                            <RadioGroup defaultValue={'rounded'} onChange={(e) => setDotsOptionsType(e)}>

                                                {dotTypes.map(dType =>
                                                    <Field key={dType.toLowerCase()} className="flex items-center gap-2 py-1">
                                                        <Radio
                                                            value={dType.toLowerCase().replace(' ', '-')}
                                                            className="group flex size-5 items-center justify-center rounded-full border bg-white data-[checked]:bg-blue-400"
                                                        >
                                                            <span className="invisible size-2 rounded-full bg-white group-data-[checked]:visible"/>
                                                        </Radio>
                                                        <Label className={'text-xs'}>{dType}</Label>
                                                    </Field>,
                                                )
                                                }
                                            </RadioGroup>
                                        </Field>
                                        <hr className={'dark:border-neutral-800 my-5'}/>
                                        <Field>
                                            <div className={'flex flex-wrap w-full'}>
                                                <Field className={'w-full 2xsl:w-1/4 xl:w-1/2 p-2'} heading={'Background Color'}>
                                                    <TwitterPicker className={''} width={'100%'} color={backgroundColor}
                                                                   onChangeComplete={setBackgroundColor}/>
                                                </Field>
                                                <Field className={'w-full xl:w-1/2 p-2'} heading={'Dots Color'}>
                                                    <TwitterPicker className={''} width={'100%'} color={dotsColor} onChangeComplete={setDotsColor}/>
                                                </Field>
                                                <Field className={'w-full xl:w-1/2 p-2'} heading={'Corner Color'}>
                                                    <TwitterPicker className={''} width={'100%'} color={cornerColor} onChangeComplete={setCornerColor}/>
                                                </Field>
                                                <Field className={'w-full xl:w-1/2 p-2'} heading={'Corner Dots Color'}>
                                                    <TwitterPicker className={''} width={'100%'} color={cornerDotsColor}
                                                                   onChangeComplete={setCornerDotsColor}/>
                                                </Field>
                                            </div>
                                        </Field>

                                    </Field>
                                    <Field>
                                        <hr className={'dark:border-neutral-800 my-5'}/>
                                        <PrimaryButton onClick={e => {qrCode.download({ extension: 'png' })}}>
                                            <FontAwesomeIcon icon={faDownload} className={'mr-2'}/>
                                            Download PNG
                                        </PrimaryButton>
                                    </Field>
                                </>
                                : <></>}
                        </div>
                        {/*<PrimaryButton processing={isProcessing} type={'submit'}>Generate QR Code!</PrimaryButton>*/}
                    </div>
                </form>

            </Field>

            <div className={'w-full lg:w-2/4 p-3.5 rounded '}>
                <div className={'sticky top-32'}>
                    <div ref={ref} className={'qrc w-full max-w-lg mx-auto aspect-square overflow-hidden'}/>
                </div>
            </div>

            <div className={'!my-10 bg-neutral-100 dark:bg-neutral-800 w-full p-5 xl:-m-5 rounded'}>
                <p className={'text-xs'}>
                    <FontAwesomeIcon icon={faGithub} className={'mr-1'}/>
                    Made possible with <br/><a href="https://github.com/kozakdenys/qr-code-styling"
                                               target={'_blank'}>https://github.com/kozakdenys/qr-code-styling <FontAwesomeIcon
                    icon={faExternalLinkAlt} className={'text-xs'}/><br/></a><a href="https://github.com/casesandberg/react-color"
                                                                                target={'_blank'}>
                    https://github.com/casesandberg/react-color <FontAwesomeIcon
                    icon={faExternalLinkAlt} className={'text-xs'}/>
                </a>
                </p>
            </div>
        </div>
    </ReactModuleContainer>
}
