import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function OutputToolsButton ({ className, children, icon, title, onClick, disabled }) {
  return <button className={'cursor-pointer transition-all text-gray-700 hover:text-gray-900 rounded-full shadow'
    + ' dark:bg-neutral-800/50 dark:text-gray-300 dark:hover:bg-blue-900/50'
    + ' hover:bg-blue-600/80 hover:text-white '
    + ' text-lg aspect-square w-8'
    + ' disabled:text-neutral-200 hover:disabled:bg-transparent '
    + ' dark:disabled:text-neutral-700/60'
    + (className ? ' ' + className : '')}
                 disabled={disabled}
                 type={'button'}
                 title={title}
                 onClick={onClick}>
    <FontAwesomeIcon icon={icon}/>
  </button>
}
