import TextArea from '@/Components/Form/TextArea.jsx'
import Field from '@/Components/Form/Field.jsx'
import {useEffect, useState} from 'react'
import InputLabel from '@/Components/Form/InputLabel.jsx'
import ModuleHead from '@/Components/Modules/ModuleHead.jsx'
import HR from '@/Components/HR.jsx'
import {Transition} from '@headlessui/react'
import ReactModuleContainer from '@/Components/Modules/ReactModuleContainer.jsx'

export default function View({className, children, module, title, meta_description}) {
    const [text, setText] = useState('')
    const [character_count, setCharacterCount] = useState(0)
    const [uniqueWords, setUniqueWords] = useState({})
    const [word_count, setWordCount] = useState(0)
    const [wordsList, setWordsList] = useState([])

    useEffect(e => {
        setCharacterCount(prev => text.length)
        setWordsList(prev => {
            let _uniqueWords = {}
            let _wordsList = text.trim().split(' ').filter(n => n !== '')

            _wordsList.forEach(x => {
                _uniqueWords[x] = (_uniqueWords[x] || 0) + 1
            })

            setUniqueWords(prev => _uniqueWords)

            // Actually set the wordsList
            return _wordsList
        })

    }, [text])

    return <ReactModuleContainer module={module}>
        <div>
            <p dangerouslySetInnerHTML={{__html: module.description}}/>
        </div>
        <div className={'flex flex-wrap w-full'}>
            <div className={'w-full lg:w-1/2 p-2'}>
                <h2>Input</h2>
                <Field>
                    <InputLabel>Text to count</InputLabel>
                    <TextArea
                        className={'w-full '} placeholder={'Enter text here...'}
                        handleChange={e => setText(p => e.target.value)}
                    />
                    <small>Enter the text above to count its characters, characters count will update automatically when
                        the text
                        changes, <i>words are separated by white space.</i></small>
                </Field>
                <HR/>
            </div>
            <div className={'w-full lg:w-1/2 p-2'}>
                <div className={''}>
                    <h2>Output</h2>
                    <table className={''}>
                        <tbody>
                        <tr>
                            <td className={'w-1/2 sm:w-4/12'}>Characters Count</td>
                            <td className={'font-bold'} colSpan={2}>{character_count}</td>
                        </tr>
                        <tr>
                            <td>Words Count</td>
                            <td className={'font-bold'} colSpan={2}>{wordsList.length}</td>
                        </tr>
                        <tr>
                            <td>Unique Words</td>
                            <td className={'font-bold'} colSpan={2}>{Object.entries(uniqueWords).length}</td>
                        </tr>
                        <tr className={'h-full'}>
                            <td className={'empty:p-0'} colSpan={3}>
                                <h3>Words List / Count</h3>
                                <small>Words are separated by a whitespace.</small>
                                <HR/>
                                {Object.entries(uniqueWords).sort(([, a], [, b]) => b - a).map((uniqueWord) => {
                                    return <div className={'odd:bg-neutral-100 dark:odd:bg-neutral-800 flex'}>
                                        <div className={'font-medium w-1/2 p-1'}>{uniqueWord[0]}</div>
                                        <div className={'font-bold w-1/2 p-1'}>{uniqueWord[1]}</div>
                                    </div>
                                })}

                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        {
            module.usage ? <div>
                <h3>Usage</h3>
                <p dangerouslySetInnerHTML={{__html: module.usage}}/>
            </div> : <></>
        }
    </ReactModuleContainer>
}
