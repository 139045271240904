import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'
import LeftSideButton from '@/Components/Sidebar/Elements/LeftSideButton'

export default function ThemeSwitcher ({ className, fn }) {

  return <>
    <>

      {fn.darkMode
        ? <LeftSideButton title={'Turn on Light mode'} fn={fn} icon={faSun}
                          onClick={fn.toggleDarkMode} text={'Turn lights On'}/>
        : <LeftSideButton  title={'Turn On Dark mode'} fn={fn} icon={faMoon}
                          onClick={fn.toggleDarkMode} text={'Turn lights Off'}/>
      }

    </>
  </>
}
