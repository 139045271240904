import ModuleLayout from '@/Layouts/ModuleLayout.jsx'
import TextArea from '@/Components/Form/TextArea.jsx'
import { useRef, useState } from 'react'
import format from 'xml-formatter/src'
import ReactModuleContainer from '@/Components/Modules/ReactModuleContainer.jsx'

export default function View ({ module }) {
  const [input, setInput] = useState('')
  const inputRef = useRef()

  const [xmlOptions, setXMLOptions] = useState({
    indentation: '\t',
    collapseContent: true,
    lineSeparator: '\r\n',
    whiteSpaceAtEndOfSelfclosingTag: true,
    throwOnFailure: false,
  })

  const getOutput = () => {

    try {
      return format(input, xmlOptions)
    } catch (exception) {
      return exception.toString()
    }
  }
  return <ReactModuleContainer module={module}>
    <div className={'flex flex-wrap w-full justify-between'}>
      <div className={'w-full xl:w-1/2 xl:pr-5'}>
        <h2>Input</h2>
        <TextArea className={'mt-2'} ref={inputRef}
                  handleChange={e => setInput(e.target.value)}
                  placeholder={'Enter XML data here...'}/>
      </div>
      <div className={'w-full xl:w-1/2'}>
        <h2>Output</h2>
        <pre className={'p-2 mt-2 bg-white dark:bg-black whitespace-break-spaces empty:opacity-0 rounded'}>{getOutput()}</pre>
      </div>
    </div>
  </ReactModuleContainer>
}
