import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PrimaryButton from '@/Components/Form/Buttons/PrimaryButton.jsx'
import Single from '@/Pages/Modules/Single.jsx'
import ReactModuleContainer from '@/Components/Modules/ReactModuleContainer.jsx'

export default function View ({ className, children, title, module }) {
    const [locationMessage, setLocationMessage] = useState('Allow')
    const [ambientLightMessage, setAmbientLightMessage] = useState('Allow')
    const [ambientLight, setAmbientLight] = useState({ illuminance: null })
    const [gravity, setGravity] = useState({})
    const [gravityMessage, setGravityMessage] = useState('Allow')
    const [gyroscope, setGyroscope] = useState({})
    const [gyroscopeMessage, setGyroscopeMessage] = useState('Allow')
    const [location, setLocation] = useState({
        coords: {}, timestamp: 0,
    })
    let sensor = null

    function getCurrentLocation () {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLocation(position)
                setLocationMessage('Acquired!')
            },
            () => {}, { enableHighAccuracy: true },
        )
    }

    function getAmbientLightSensor () {
        // Check if the browser supports the API.
        if ('GravitySensor' in window) {
            // Get permission from the user to access the sensor.
            navigator.permissions.query({ name: 'ambient-light-sensor' }).then(function (result) {
                if (result.state === 'granted') {

                    setAmbientLightMessage('Permission Granted')

                    // Create an AmbientLightSensor object.
                    const als = new AmbientLightSensor()
                    setAmbientLightMessage('Created...')
                    // Listen for changes in the sensor's readings.
                    als.addEventListener('change', function () {
                        // Access the sensor's readings.
                        const illuminance = als.illuminance
                        setAmbientLight(als)
                        setAmbientLightMessage(JSON.stringify(als))
                        // Do something with the sensor's readings.
                        console.log('The illuminance is', illuminance)
                    })
                }
            })
        } else {
            setAmbientLightMessage('Ambient Light sensor not available.')
        }
    }

    function getGyroscope () {
        // Check if the browser supports the gyroscope sensor API.
        if ('Gyroscope' in window) {

            // Get permission from the user to access the sensor.
            navigator.permissions.query({ name: 'gyroscope' }).then(function (result) {
                setGravityMessage('Querying Gyroscope Permission')

                if (result.state === 'granted') {
                    // Create a Gyroscope object.f
                    const gs = new Gyroscope()

                    // Listen for changes in the sensor's readings.
                    gs.addEventListener('change', function () {
                        setGyroscopeMessage('Gyroscope change event fired.')
                        // Access the sensor's readings.
                        const x = gs.x
                        const y = gs.y
                        const z = gs.z

                        setGyroscope({ x: x, y: y, z: z })
                        setGyroscopeMessage('Gyroscope Updated.')

                        // Do something with the sensor's readings.
                        console.log('The gyroscope sensor readings are', x, y, z)
                    })
                }
            }).catch(e => setGyroscopeMessage('Error: ' + e))
        } else {
            setGyroscopeMessage('Not available.')
        }
    }

    function getGravitySensor () {
        // Check if the browser supports the gravity sensor API.
        if ('GravitySensor' in window) {

            // Get permission from the user to access the sensor.
            navigator.permissions.query({ name: 'gravity-sensor' }).then(function (result) {
                setGravityMessage('Querying')
                if (result.state === 'granted') {

                    // Create a GravitySensor object.
                    const gs = new GravitySensor()
                    setGravity(gs)
                    setGravityMessage('Loaded')
                    // Listen for changes in the sensor's readings.
                    gs.addEventListener('change', function () {
                        setGravityMessage('Change e fired.')
                        // Access the sensor's readings.
                        const x = gs.x
                        const y = gs.y
                        const z = gs.z

                        // Do something with the sensor's readings.
                        console.log('The gravity sensor readings are', x, y, z)
                    })
                }
            }).catch(e => setGravityMessage('Error: ' + e))
        } else {
            setGravityMessage('Not available.')
        }
    }

    function getLocationSensor () {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
            setLocationMessage('Querying')
            switch (result.state) {
                case 'granted':
                    setLocationMessage('Acquired')
                    getCurrentLocation()
                    break

                case 'prompt': {
                    setLocationMessage('Prompting')
                    getCurrentLocation()
                    break
                }
                case'denied': {
                    setLocationMessage('Denied!')
                    break
                }
                default:
                    setLocationMessage('Error')
            }
            result.addEventListener('change', () => {
                setLocationMessage(result.state)
            })
        }).catch(e => {
            setLocationMessage('Error')
        })
    }

    const InfoTD = ({ title, icon, children }) => <div className={'w-full 2xl:w-1/4 p-0.5'}>
        <div className={'rounded bg-neutral-100 dark:bg-neutral-900 p-2 px-3 flex flex-wrap'}>
            {icon ? <FontAwesomeIcon className={'mr-1'} icon={icon}/> : <></>}
            <span className={'dark:text-neutral-600 font-medium mx-2'}>{title}</span>
            <strong className={'dark:text-white'}>{children}</strong>
        </div>
    </div>

    const MainColumn = ({ children, title, message, action }) => <div className={'flex w-full flex-wrap my-5'}>
        <div className={'text-right w-full xl:w-3/12 p-2'}>
            {title}
            <p className={'text-xs w-full'}>
                <div className={'mt-2'}>
                    <PrimaryButton onClick={action}>{message}</PrimaryButton>
                    <small className={'block'}>
                        {title} permission is required to use this sensor.
                    </small>
                </div>
            </p>
        </div>
        <div className={'w-full xl:w-9/12 rounded text-left dark:bg-neutral-800 p-2'}>{children}</div>
    </div>

    return <ReactModuleContainer module={module}>
        <div>
            <div className={'table w-full'}>

                <MainColumn title={'Location Service'} message={locationMessage} action={getLocationSensor}>
                    <div className={'flex flex-wrap'}>
                        <InfoTD title={'Latitude'}>{location.coords.longitude ?? 'NULL'}</InfoTD>
                        <InfoTD title={'Longitude'}>{location.coords.longitude ?? 'NULL'}</InfoTD>
                        <InfoTD title={'Accuracy'}>{location.coords.accuracy ?? 'NULL'}</InfoTD>
                        <InfoTD title={'Altitude'}>{location.coords.altitude ?? 'NULL'}</InfoTD>
                        <InfoTD title={'Altitude Accuracy'}>{location.coords.altitudeAccuracy ?? 'NULL'}</InfoTD>
                        <InfoTD title={'Heading'}>{location.coords.heading ?? 'NULL'}</InfoTD>
                        <InfoTD title={'Speed'}>{location.coords.speed ?? 'NULL'}</InfoTD>
                    </div>
                </MainColumn>

                <MainColumn title={'Ambient Light'} message={ambientLightMessage} action={getAmbientLightSensor}>
                    {JSON.stringify(ambientLight)}
                </MainColumn>

                <MainColumn title={'Gravity Sensor'} message={gravityMessage} action={getGravitySensor}>
                    {JSON.stringify(ambientLight)}
                </MainColumn>

                <MainColumn title={'Gyroscope Sensor'} action={getGyroscope} message={gyroscopeMessage}>
                    {JSON.stringify(ambientLight)}
                </MainColumn>

            </div>
        </div>
    </ReactModuleContainer>
}
