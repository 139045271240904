import { useState } from 'react'

export default function CheckboxGroup ({ className, disabled, input, _checked = false, fn }) {
  const { options } = input
  const [checked, setChecked] = useState(options.filter(e => e.checked).map(e => e.value))

  // Add/Remove checked item from list
  const handleCheck = (event) => {

    if (event.target.type === 'radio')
      return fn.form.setData(event.target.name, [event.target.value])

    let updatedList = [...checked]
    if (event.target.checked) updatedList = [...checked, event.target.value]
    else updatedList.splice(checked.indexOf(event.target.value), 1)

    setChecked(updatedList)
    fn.form.setData(input.name, updatedList)
  }

  return <div className={'flex flex-wrap items-top justify-start -ml-3'}>

    {options.map(option =>
      <label key={option.value}
             className={'cursor-pointer text-sm '
               + ' hover:bg-indigo-50'
               + ' dark:bg-transparent dark:hover:bg-neutral-900 transition-all rounded px-3 py-2'
               + (options.length > 1 ? ' w-1/2 ' : ' w-full')
             }>
        <input
          className={'mr-2 w-3.5 h-3.5 text-blue-600 bg-gray-100 border-gray-300'
            + ' dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-0 focus:outline-none'
            + (input.checkbox_type === 'radio' ? ' rounded-full' : ' rounded')
            + ' dark:bg-gray-700 dark:border-gray-600'}

          id={option.name}
          name={input.multi ? input.name + '[]' : input.name}
          onChange={e => handleCheck(e)}
          defaultChecked={option.checked}
          defaultValue={option.value}
          type={input.checkbox_type || 'checkbox'}
        />

        <span className={'font-medium'}>{option.label}</span>
        <span className={'block text-xs dark:text-neutral-500 text-neutral-700'}>{option.label_description}</span>
      </label>,
    )}

  </div>
}

