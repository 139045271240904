import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLink} from "@fortawesome/free-solid-svg-icons";

export default function Footer() {
    return <footer className={'w-full select-none mt-3 p-2 pl-4 text-neutral-800 dark:text-neutral-500'}>
        <div className={'flex flex-wrap pr-2 justify-around items-center text-xs'}>
            <div className={'w-full'}>
                <a className={'w-full transition-all hover:text-blue-500 dark:hover:text-lime-200 mb-1 block'} href={'/changelog'}>
                    Changelog
                </a>
                <p>&copy;{new Date().getFullYear()} - Onlinetool.app</p>
            </div>
            <div className={'mt-1 flex gap-x-2 lg:justify-start justify-between w-full'}>
                <a href={'/privacy'}>Privacy Policy</a>
                <a href={'/terms'}>Terms &amp; of Use</a>
                <a href={'/cookies'}>Cookies</a>
                <a href={'/objective'}>Objective</a>
            </div>
        </div>
    </footer>
}
