import React, {useEffect, useRef, useState} from 'react'
import {Head, Link, router, usePage} from '@inertiajs/react'

import Sidebar from '@/Components/Sidebar/Sidebar.jsx'
import DefaultTheme from 'tailwindcss/defaultTheme'
import {AppContext} from '@/Context/AppContext.jsx'
import {Toaster} from 'react-hot-toast'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLink} from "@fortawesome/free-solid-svg-icons";
import Footer from "@/Components/Footer.jsx";

let fm = {}
export default function Wrapper({children}) {

    const {title, meta_description, slogan, showTitle} = usePage().props

    const [documentTitle, setDocumentTitle] = useState('')
    const [documentDescription, setDocumentDescription] = useState(meta_description)

    const [toggleSidebarOnWindowResize, setToggleSidebarOnWidowResize] = useState(true)
    const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth > parseInt(DefaultTheme.screens.lg))
    const [darkMode, setDarkMode] = useState(0)
    const pageTitleRef = useRef()

    router.on('start', (e) => {
        if (window.innerWidth <= parseInt(DefaultTheme.screens.md)) {
            setSidebarOpen(false)
        }
    })

    useEffect(prev => {

        if ((localStorage.theme === 'dark' ||
            (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches))) {

            document.documentElement.classList.add('dark')
            setDarkMode(1)
        } else {
            document.documentElement.classList.remove('dark')
            setDarkMode(0)
        }

    }, [])

    useEffect(() => {
        setDocumentTitle(title)
        setDocumentDescription(meta_description)
    }, [title, meta_description])
    const toggleDarkMode = () => {

        // toggle Dark mode 0|1
        setDarkMode(!darkMode)

        // toggle Dark mode className on|off
        darkMode ? document.documentElement.classList.remove('dark')
            : document.documentElement.classList.add('dark')

        // toggle storage theme
        localStorage.theme = darkMode ? 'light' : 'dark'

        console.info('%c theme:', 'color:green', localStorage.theme)
    }

    const toggleSidebarOpen = () => {
        setToggleSidebarOnWidowResize(false)
        setSidebarOpen(prevState => !sidebarOpen)
    }

    const fn = {
        isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
        darkMode: darkMode,

        setDocumentTitle: setDocumentTitle,
        documentTitle: documentTitle,
        setDocumentDescription: setDocumentDescription,
        documentDescription: documentDescription,
        toggleDarkMode: toggleDarkMode,
        sidebar: {
            sidebarOpen: sidebarOpen,
            setSidebarOpen: setSidebarOpen,
            toggleSidebarOpen: toggleSidebarOpen,
            toggleSidebarOnWindowResize: toggleSidebarOnWindowResize,
            setToggleSidebarOnWidowResize: setToggleSidebarOnWidowResize,
            closeSidebarOnBlur: () => {
                window.innerWidth < parseInt(DefaultTheme.screens.lg) && fn.sidebar.setSidebarOpen(false)
            },
        },
    }
    fm = {...fm, ...fn}

    return <>
        <Head>
            <title>{documentTitle}</title>
            <meta name={'og:title'} content={documentTitle}/>
            <meta name={'description'} content={documentDescription}/>
            <meta name={'og:description'} content={documentDescription}/>
        </Head>

        <div className={'m'}
             data-sidebar-open={fn.sidebar.sidebarOpen}>

            <Sidebar fn={fn}/>

            <div className={'wrapper'
                + (fn.sidebar.sidebarOpen ? ' lg:ml-[295px] md:px-4 lg:px-7 ' : '')}>
                <main className={'pb-[4em] rounded-lg content mx-auto' + (fn.sidebar.sidebarOpen ? ' sm:px-10' : '')}>

                    {showTitle
                        ? <h1 ref={pageTitleRef}
                              className={'dark:border-b-neutral-800 text-2xl sticky top-0 z-20 md:text-3xl font-extrabold bg-white' +
                                  ' dark:bg-neutral-900 mb-10 py-5'
                                  + (fn.sidebar.sidebarOpen ? '' : ' lg:pl-0 pl-12 ')}>
                            {title}
                        </h1>
                        : <></>
                    }

                    <AppContext.Provider value={{...fn}}>
                        {children}
                    </AppContext.Provider>
                </main>
            </div>
        </div>
        {!fn.sidebar.sidebarOpen ?
            <Footer/> : <></>}
        <Toaster position={'bottom-right'}/>
    </>
}
