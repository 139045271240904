import ModuleHead from '@/Components/Modules/ModuleHead.jsx'
import React, { useState } from 'react'
import Input from '@/Components/Form/Input.jsx'
import PrimaryButton from '@/Components/Form/Buttons/PrimaryButton.jsx'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Field from '@/Components/Form/Field.jsx'
import InputLabel from '@/Components/Form/InputLabel.jsx'

export default function View ({ module }) {

    const [username, setUsername] = useState('')
    const options = {
        showLabel: true, showLabelDescription: true, showPlaceholder: true,
    }

    const handleChange = (e) => {
        setUsername(e.target.value)
    }

    const run = () => {
        const url = 'https://i.instagram.com/api/v1/users/web_profile_info/?username=therock'

        axios.get(url).then(
            r => {},
        )
    }

    return <>
        <div className={'w-11/12'}>{options.showHead
            ? <ModuleHead
                module={module}
                size={size} fn={fn} options={options}/>
            : <></>}</div>

        <Field>
            <InputLabel forInput={'username'}>Username</InputLabel>
            <Input id={'username'} placeholder={'Enter Instagram Username here'}
                   handleChange={handleChange}/>
        </Field>

        <PrimaryButton icon={faChevronRight} onClick={run}>Run</PrimaryButton>
    </>
}
