import React, { useEffect } from 'react'

export default function ArticleContainer ({ className, headerClassName, title, children }) {

    useEffect(() => {

    }, [])

    return <article className={'mx-auto delay-100 duration-1000 dark:p-7 px-10 mt-14 dark:px-12 dark:py-10' +
        (className ? ' ' + className : '')}>
        <h1 className={'text-6xl font-bold select-none text-center mb-20 mt-10 ' + (headerClassName ? ' ' + headerClassName : '')}>{title}</h1>
        <div>
            {children}
        </div>
    </article>
}
