import ArticleContainer from '@/Components/ArticleContainer.jsx'

export default function Cookies ({ className, children, title }) {
    return <ArticleContainer title={title}>
        <p>Welcome to OnlineTool.App! We're committed to providing you with a user-friendly and informative experience. This article explains how we use cookies on our website to achieve
            those goals.</p>
        <h2>What are cookies?</h2>
        <p>Cookies are small data files placed on your device by websites you visit. They store information, like your preferences, browsing history, and other details, that can be used to
            improve your future visits.</p>
        <h2>Why does OnlineTool.App use cookies?</h2>
        <p>We use cookies for several important reasons:</p>
        <p>Understanding user behavior: Cookies help us analyze how users interact with our website. This allows us to improve the user experience by identifying areas for optimization and
            tailoring content to your needs.
            Personalization: While we don't offer public login yet, cookies can help personalize your experience to a certain extent, like remembering your preferred language settings on
            future visits.
            Advertising effectiveness (Google Ads): We use Google Ads to support our platform's development. Cookies allow Google Ads to show you relevant advertisements based on your
            browsing history.</p>
        <h2>What types of cookies does OnlineTool.App use?</h2>
        <p>There are two main types of cookies we use:</p>

        <p>Session cookies: These temporary cookies are stored on your device until you close your browser. They are used to track your activity during your current visit and are not
            retained once you leave the website.
            Persistent cookies: These cookies remain on your device for a specific period (set by the cookie itself) even after you close your browser. This allows us to recognize you on
            your next visit and potentially personalize your experience.</p>
        <h2>How can you control cookies?</h2>
        <p>Most web browsers allow you to control or disable cookies in your settings. You can choose to accept all cookies, be notified when a cookie is being placed, or reject all cookies.
            However, disabling cookies may affect your experience on OnlineTool.App and other websites.</p>
        <h2>Data privacy at OnlineTool.App</h2>
        <p>We take data privacy seriously. We do not collect any personal information through cookies without your explicit consent. Information gathered through cookies is anonymized and
            used for statistical purposes only.</p>
        <h2>Changes to our cookie policy</h2>
        <p>This cookie policy is effective as of September 2, 2024. We may update our cookie policy from time to time to reflect changes in technology or legislation. We will post any changes on this page.</p>
    </ArticleContainer>
}
