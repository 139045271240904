import Input from '@/Components/Form/Input'
import PrimaryButton from '@/Components/Form/Buttons/PrimaryButton'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export default function View () {
  return <>

    <div>
      <div className={'w-1/2 mx-auto'}>
        <Input name={'vin'} placeholder={'VIN Number...'} primary={true} autoFocus={true}/>

        <PrimaryButton icon={faSearch}>Check VIN</PrimaryButton>
      </div>
    </div>
  </>
}
