import './bootstrap'
import '../css/app.css'

import axios from 'axios'
import {createRoot} from 'react-dom/client'
import {createInertiaApp} from '@inertiajs/react'
import Wrapper from '@/Layouts/Wrapper'

axios.defaults.withCredentials = true
createInertiaApp({
    title: (title) => `${title}`,
    resolve: (name) => {
        const pages = import.meta.glob('./Pages/**/*.jsx', {eager: true})
        let page = pages[`./Pages/${name}.jsx`]
        page.default.layout = page.default.layout || (page => <Wrapper children={page}/>)
        return page
    },
    setup({el, App, props}) {
        const root = createRoot(el)
        root.render(<App {...props} />)
    },
    progress: {
        color: '#3857de',
        showSpinner: true,
        includeCSS: true,
    },
})
