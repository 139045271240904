import PageContainer from '@/Components/PageContainer.jsx'
import ArticleContainer from "@/Components/ArticleContainer.jsx";

export default function Terms({title}) {
    return <ArticleContainer title={title}>
        <h2>Introduction</h2>

        <p>These terms and conditions ("Terms") govern your access to and use of [website name] ("Website"), operated by
            [your company name] ("we," "us," or "our").</p>

        <h2>Acceptance of Terms</h2>

        <p>By accessing or using the Website, you agree to be bound by these Terms. If you disagree with any part of the
            terms then you may not access the Website.</p>

        <h2>Changes to Terms</h2>

        <p>We may revise and update these Terms at our sole discretion. The revised Terms will be effective immediately
            upon posting on the Website.</p>

        <h2>Content</h2>

        <p>The Website contains content ("Content") owned by us or our licensors. This Content is protected by
            copyright, trademark, and other intellectual property laws.</p>

        <h2>User Conduct</h2>

        <p>You agree to use the Website in a lawful manner and in accordance with these Terms. You agree not to use the
            Website for any purpose that is prohibited by law or regulation.</p>

        <h2>Termination</h2>

        <p>We may terminate or suspend your access to the Website for any reason, at any time, without notice.</p>

        <h2>Disclaimer</h2>

        <p>The Website is provided "as is" and without warranties of any kind, express or implied.</p>

        <h2>Limitation of Liability</h2>

        <p>In no event shall we be liable for any damages arising out of or related to your use of the Website.</p>
    </ArticleContainer>
}
