export default function TextArea({
                                     ref,
                                     children,
                                     className,
                                     name,
                                     placeholder,
                                     defaultValue,
                                     autoFocus = false,
                                     autoComplete = '',
                                     rows = 3,
                                     required = false,
                                     handleChange,
                                     disabled,
                                     primary = false,
                                     id,
                                     value,
                                 }) {
    return <div>
    <textarea
        ref={ref}
        id={id}
        className={'w-full border-indigo-100 rounded dark:border-0 text-sm'
            + ' disabled:bg-neutral-100 '
            + (className ? ' ' + className : '')
            + (
                primary
                    ? ' ring-1 dark:ring-indigo-400  dark:text-neutral-200 dark:bg-neutral-500/50'
                    : ' dark:text-neutral-400 dark:bg-neutral-700/50'
            )}
        autoFocus={autoFocus}
        name={name}
        placeholder={placeholder}
        autoComplete={autoComplete}
        rows={rows}
        required={required}
        onChange={handleChange}
        disabled={disabled}
        value={value}
        defaultValue={children}></textarea>
    </div>
}
