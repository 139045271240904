// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
import React, { useEffect, useRef } from 'react'

/**
 * Hook that alerts clicks outside the passed ref
 */
export function useOutsideAlerter (ref, fn) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside (event) {
      if (ref.current && !ref.current.contains(event.target)) {
        fn.sidebar.closeSidebarOnBlur()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [ref])
}

/**
 * Component that alerts if you click outside of it
 */
export default function OutsideAlerter (props) {
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return <div ref={wrapperRef}>{props.children}</div>
}