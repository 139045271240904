import ReactModuleContainer from '@/Components/Modules/ReactModuleContainer.jsx'
import {useState} from 'react'
import SecondaryButton from "@/Components/Form/Buttons/SecondaryButton.jsx";
import toast from "react-hot-toast";
import {faDownload, faPlay, faRecordVinyl, faSave, faStop} from "@fortawesome/free-solid-svg-icons";
import {router} from '@inertiajs/react'

export default function View({module}) {
    const units = [
        {label: 'm/s', multiply: 1},
        {label: 'km/h', multiply: 3.6},
        {label: 'mph', multiply: 2.237},
    ]

    const [recordingStatus, setRecordingStatus] = useState(0)

    const [positionData, setPositionData] = useState([])

    const [speed, setSpeed] = useState(null)
    const [unit, setUnit] = useState(units[0])
    const [isWatching, setIsWatching] = useState(0)

    const watchPosition = () => {
        if ('geolocation' in navigator) {
            setIsWatching(navigator.geolocation.watchPosition(
                successCallback,
                errorCallback,
                {enableHighAccuracy: true},
            ))
        } else {
            // Geolocation API is not supported
        }
    }

    function successCallback(position) {
        setPositionData(prevState => [...prevState, position])
        setSpeed(position.coords?.speed ?? 0)
    }

    function errorCallback(error) {
        console.error('Error:', error)
    }

    const SpeedSelector = ({u}) =>
        <div
            className={'py-2 px-5 transition-all hover:bg-blue-500/50 hover:text-white select-none cursor-pointer rounded ' +
                (unit.label === u.label ? ' !bg-blue-500 !text-white ' : '')}
            onClick={() => setUnit(u)}>
            {u.label}
        </div>


    const toggleRecordingStatus = () => {
        switch (recordingStatus) {
            case 0: {
                watchPosition()
                setRecordingStatus(1)
                break;
            }
            default: {
                setRecordingStatus(0)
                // Clear watch
                navigator.geolocation.clearWatch(isWatching)
                // Set Watching ID to 0
                setIsWatching(0)
            }
        }
    }

    // In react, navigating away will not disable location tracking
    // and the process will remain running even when you navigate away
    // so as a workaround refresh the page, to disable location tracking.
    router.on('navigate', function () {
        if (isWatching)
            window.location.reload()
    })

    return <ReactModuleContainer module={module}>
        <div className={'p-10 rounded-xl max-w-2xl dark:shadow  mx-auto'}>

            <div className={'text-center mb-10 select-none'}>
                <div className={'relative text-[9rem] md:text-12xl md:-mb-12 md:-mt-28 font-bold'}>
                    {speed !== null ? parseInt(speed).toPrecision(3) * unit.multiply : '0'}
                </div>
                <span>{unit.label}</span>
            </div>

            <div className={'flex gap-x-2 justify-center items-center text-sm'}>
                <SpeedSelector u={units[0]}/>
                <SpeedSelector u={units[1]}/>
                <SpeedSelector u={units[2]}/>
            </div>


        </div>
        <div className={'my-10'}>

            <div
                className={'my-10 flex gap-x-2 justify-center'}>
                <SecondaryButton
                    icon={isWatching ? faStop : faPlay}
                    onClick={toggleRecordingStatus}/>
                <SecondaryButton
                    disabled={!positionData.length}
                    icon={faSave}
                    title={'Save Logs to Browser Storage'}/>
                <SecondaryButton
                    disabled={!positionData.length}
                    icon={faDownload}
                    title={'Download Logs'}/>
                <SecondaryButton
                    disabled={!positionData.length}
                    onClick={() => setPositionData([]) || toast(positionData.length ? 'Logs have been cleared' : 'Nothing to clear')}>
                    Clear Logs
                </SecondaryButton>
            </div>

            {positionData.length ? <>
                <div className={'flex justify-between py-5 px-3 select-none text-neutral-400 dark:text-neutral-500'}>
                    <div className={'w-1/4'}>Coordinates</div>
                    <div className={'w-1/4'}>Speed</div>
                    <div className={'w-1/4'}>Altitude</div>
                    <div className={'w-1/4'}>Misc.</div>
                </div>
                {positionData.map(p => <div
                    className={'flex items-center w-full flex-wrap dark:odd:bg-neutral-800 odd:bg-neutral-100 py-2 px-4 rounded text-xs'}>

                    <div className={'w-1/4'}>
                        <p><strong title={'Accuracy'}>Acc</strong> {p.coords.accuracy?.toPrecision(4) ?? 'NA'}</p>
                        <p><strong title={'Longitude'}>Long</strong> {p.coords.longitude}</p>
                        <p><strong title={'Latitude'}>Lat</strong> {p.coords.latitude}</p>

                    </div>
                    <div className={'w-1/4'}>
                        {p.coords.speed ? <>
                            <p>{p.coords.speed?.toPrecision(3) * units[0].multiply} m/s</p>
                            <p>{p.coords.speed?.toPrecision(3) * units[1].multiply} km/h</p>
                            <p>{p.coords.speed?.toPrecision(3) * units[2].multiply} mph</p>
                        </> : <span className={'select-none'}>-</span>}
                    </div>
                    <div className={'w-1/4'}>
                        <p><strong title={'Altitude'}>Alt</strong> {p.coords.altitude ?? 'NA'}</p>
                        <p><strong
                            title={'Altitude Accuracy'}>Acc</strong> {p.coords.altitudeAccuracy?.toPrecision(4) ?? 'NA'}
                        </p>
                    </div>
                    <div className={'w-1/4'}>
                        <p><strong>Heading</strong> {p.coords.heading?.toPrecision(3) ?? 'NA'}</p>
                        <p><strong>Timestamp</strong> {p.timestamp ? (new Date(p.timestamp).toLocaleString()) : 'NA'}
                        </p>
                    </div>
                </div>)}
            </> : <></>}
        </div>
    </ReactModuleContainer>
}
