import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef, useState } from 'react'

export default function Rating ({ className, item, canVote }) {

  const [rating, setRating] = useState(0)

  const ratingRef = useRef()
  useEffect(r => {
    axios.post('/user_requests/' + item.id + '/get_rating').then(r => {
        setRating(r.data.rating)
      },
    )
  })
  const rate = (item, dir) => {
    if (canVote) {

      axios.post(item, { rate: dir }).then(r => {
        setRating(r.data.rating)
      }).finally(f => {

      })
    } else {

    }
  }

  return <>

    <div className={'hover:bg-indigo-100 p-1 pb-0 rounded-lg cursor-pointer transition-all'}>
      <button onClick={() => rate('/user_requests/' + item.id + '/rate', 1)}>
        <FontAwesomeIcon className={'select-none'} icon={faChevronUp}/>
      </button>
    </div>

    <div className={'my-1 font-bold select-none'} ref={ratingRef}>
      {rating}
    </div>

    <div className={'hover:bg-indigo-100 p-1 pb-0 rounadded-lg cursor-pointer transition-all'}>
      <button onClick={() => rate('/user_requests/' + item.id + '/rate', -1)}>
        <FontAwesomeIcon className={'select-none'} icon={faChevronDown}/>
      </button>
    </div>


  </>
}