import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Link} from '@inertiajs/react'
import {useEffect, useState} from 'react'

export default function LeftSideButton({
                                           index,
                                           className,
                                           faClassName,
                                           notShownClassName,
                                           icon,
                                           link = null,
                                           title = '',
                                           text = '',
                                           textClassName,
                                           disabled = false,
                                           onClick,
                                       }) {
    const [isShown, setIsShown] = useState(false)

    useEffect(() => {
        let timeOut = setTimeout(e => setIsShown(true), parseInt(index + 0.1) * 30)
        return () => clearTimeout(timeOut)
    }, [isShown])

    return isShown ? <div key={index}
                          className={'text-center transition-all animate-fade px-3 py-2 flex items-center content-center justify-center cursor-pointer select-none '
                              + ' dark:text-neutral-400 dark:hover:text-white hover:bg-blue-700/80 hover:text-white rounded'
                              + (className ? ' ' + className : '')}
                          tabIndex={-1}
                          title={title}
                          onClick={onClick}>

        <div>

            {link
                ? <Link className={'disabled:opacity-30 '} href={link} title={title} as={'button'}
                        disabled={disabled} tabIndex={-1}
                        preserveState>

                    {icon ?
                        <FontAwesomeIcon className={'' + (faClassName ? ' ' + faClassName : '')} icon={icon}/> : <></>}
                    {text ?
                        <span className={'text-xs ml-2 ' + (textClassName ? textClassName : '')}>{text}</span> : <></>}
                </Link>
                : <>
                    {icon ?
                        <FontAwesomeIcon className={'' + (faClassName ? ' ' + faClassName : '')} icon={icon}/> : <></>}
                    {text ?
                        <span className={'text-xs ml-2 ' + (textClassName ? textClassName : '')}>{text}</span> : <></>}
                </>
            }
        </div>

    </div> : <div className={'animate-fade aspect-square select-none '
        + ((notShownClassName || className) ? ' ' + (notShownClassName || className) : '')}/>

}
