import {Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions} from '@headlessui/react'
import React, {useEffect, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle, faChevronDown} from '@fortawesome/free-solid-svg-icons'

export default function ComboboxComponent({
                                              name, label, className,

                                              disabled, options = [], placeholder, onChange = () => {
    }, selectedOption = {}
                                          }) {

    const [query, setQuery] = useState('')
    const [selected, setSelected] = useState(selectedOption)

    const filteredOptions =
        query === ''
            ? options
            : options.filter((option) => {
                return option.label.toLowerCase().includes(query.toLowerCase())
            })

    useEffect(() => {
        setSelected(selectedOption)
    }, [selectedOption?.value])

    return <div className={'z-50 relative' + (className ? ' ' + className : '')}>
        <input type="hidden" name={name} value={selected?.value}/>
        <Combobox value={selected}
                  disabled={disabled}
                  onChange={(value) => setSelected(value) || onChange(value)}
                  onClose={() => setQuery('')}>
            {label ? <label className={'select-none block text-sm -mb-1.5 mt-2'}>{label}</label> : <></>}
            <div className="relative">
                <ComboboxInput
                    placeholder={placeholder}
                    className={'w-full my-2 p-2 transition-all rounded border-2 border-indigo-300 ' +
                        'dark:border-indigo-400/50 dark:bg-neutral-800 ' +
                        'focus:border-indigo-400 hover:border-indigo-400 ' +
                        'disabled:opacity-20 disabled:dark:border-violet-400/20 w-full py-2.5 pr-8 pl-3 text-sm/6'}
                    displayValue={(option) => option?.label || options?.title}
                    onChange={(event) => setQuery(event.target.value)}
                />
                <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
                    <FontAwesomeIcon icon={faChevronDown}
                                     className="size-4 fill-indigo-500 group-data-[hover]:fill-white"/>
                </ComboboxButton>
            </div>

            <ComboboxOptions
                anchor="bottom"
                transition
                className={
                    'w-[var(--input-width)] !max-h-[300px] z-[100000] dark:bg-black/40 backdrop-blur-2xl rounded border shadow-xl border-indigo-500 dark:border-indigo-400/40 mt-0.5 bg-white ' +
                    '[--anchor-gap:var(--spacing-1)] empty:invisible transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                }>

                {filteredOptions?.map((option) => (
                    <ComboboxOption
                        key={Math.random() + '_' + parseInt(option.id)}
                        value={option}
                        className="dark:text-white group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-indigo-500/10"
                        onClick={option.onClick}>

                        <FontAwesomeIcon icon={faCheckCircle}
                                         className="invisible size-4 fill-white group-data-[selected]:visible"/>
                        <div className="text-sm">{option.label}</div>

                    </ComboboxOption>
                ))}
            </ComboboxOptions>
        </Combobox>
    </div>
}
