import React, {useEffect} from 'react'
import Checkbox from '@/Components/Form/Checkbox'
import InputError from '@/Components/Form/InputError'
import InputLabel from '@/Components/Form/InputLabel'
import PrimaryButton from '@/Components/Form/Buttons/PrimaryButton'
import TextInput from '@/Components/Form/Input.jsx'
import {Link, useForm} from '@inertiajs/react'

export default function Login({fn, status, canResetPassword, ...props}) {
    const {data, setData, post, processing, errors, reset} = useForm({
        email: '',
        password: '',
        remember: '',
    })

    useEffect(() => {
        return () => {
            reset('password')
        }
    }, [])

    const onHandleChange = (event) => {
        setData(event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value)
    }

    const submit = (e) => {
        e.preventDefault()
        post('/login&pin=1234')
    }

    return <>
        {status ? <div className="mb-4 font-medium text-sm text-green-600">{status}</div> : <></>}
        <form onSubmit={submit}>
            <div>
                <InputLabel forInput="email" value="Email"/>

                <TextInput
                    type="text"
                    name="email"
                    value={data.email}
                    className="mt-1 block w-full"
                    autoComplete="username"
                    isFocused={true}
                    handleChange={onHandleChange}
                />

                <InputError message={errors.email} className="mt-2"/>
            </div>

            <div className="mt-4">
                <InputLabel forInput="password" value="Password"/>

                <TextInput
                    type="password"
                    name="password"
                    value={data.password}
                    className="mt-1 block w-full"
                    autoComplete="current-password"
                    handleChange={onHandleChange}
                />

                <InputError message={errors.password} className="mt-2"/>
            </div>

            <div className="block mt-4">
                <label className="flex items-center">
                    <Checkbox name="remember" value={data.remember} handleChange={onHandleChange}/>

                    <span className="ml-2 text-sm text-gray-600">Remember me</span>
                </label>
            </div>

            <div className="flex items-center justify-end mt-4">
                {canResetPassword && (
                    <Link
                        href={route('password.request')}
                        className="underline text-sm text-gray-600 hover:text-gray-900"
                    >
                        Forgot your password?
                    </Link>
                )}

                <PrimaryButton className="ml-4" processing={processing}>
                    Log in
                </PrimaryButton>
            </div>
        </form>
    </>
}
