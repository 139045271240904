import Input from '@/Components/Form/Input.jsx'
import InputLabel from '@/Components/Form/InputLabel.jsx'
import ModuleLayout from '@/Layouts/ModuleLayout.jsx'
import { useEffect, useRef, useState } from 'react'
import Checkbox from '@/Components/Form/Checkbox.jsx'

export default function View ({ module }) {
    const [chmod, setChmod] = useState('775')
    const [owner4, setOwner4] = useState()
    const group4 = useRef()
    const other4 = useRef()
    const owner2 = useRef()
    const group2 = useRef()
    const other2 = useRef()
    const owner1 = useRef()
    const group1 = useRef()
    const other1 = useRef()

    function octalchange () {
        let ownerbin = parseInt(chmod.charAt(0)).toString(2)
        while (ownerbin.length < 3) { ownerbin = '0' + ownerbin }

        let groupbin = parseInt(chmod.charAt(1)).toString(2)
        while (groupbin.length < 3) { groupbin = '0' + groupbin }

        let otherbin = parseInt(chmod.charAt(2)).toString(2)
        while (otherbin.length < 3) { otherbin = '0' + otherbin }

        owner4.current.checked = parseInt(ownerbin.charAt(0))
        owner2.current.checked = parseInt(ownerbin.charAt(1))
        owner1.current.checked = parseInt(ownerbin.charAt(2))
        group4.current.checked = parseInt(groupbin.charAt(0))
        group2.current.checked = parseInt(groupbin.charAt(1))
        group1.current.checked = parseInt(groupbin.charAt(2))
        other4.current.checked = parseInt(otherbin.charAt(0))
        other2.current.checked = parseInt(otherbin.charAt(1))
        other1.current.checked = parseInt(otherbin.charAt(2))
        calc_chmod(1)
    }

    useEffect(() => {
        octalchange()
    }, [
        owner4, group4, other4,
        owner2, group2, other2,
        owner1, group1, other1,
    ])

    function calc_chmod (nototals) {
        setChmod('okk')
        owner4.current.checked = true
        alert('ok ')
        var users = ['owner', 'group', 'other']
        var totals = ['', '', '']
        var syms = ['', '', '']
        for (var i = 0; i < users.length; i++) {
            var user = users[i]
            var field4 = user + '4'
            var field2 = user + '2'
            var field1 = user + '1'
            //var total = "t_" + user;
            var symbolic = 'sym_' + user
            var number = 0
            var sym_string = ''

            totals[i] = totals[i] + number
            syms[i] = syms[i] + sym_string

        }
    }

    return <ModuleLayout module={module}>
        <div className={'max-w-md mx-auto'}>
            <InputLabel>Text</InputLabel>
            <Input
                className={'max-w-md mx-auto'}
                placeholder={'e.g 0755'}
                defaultValue={chmod}
                handleChange={e => setChmod(e.target.value)}
            /></div>
        <table className={'max-w-md mx-auto'}>
            <thead>
            <tr>
                <th></th>
                <th>Owner</th>
                <th>Group</th>
                <th>Other</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Read</td>
                <td><Checkbox ref={owner4} name={'owner4'}/></td>
                <td><Checkbox ref={group4} name={'group4'}/></td>
                <td><Checkbox ref={other4} name={'other4'}/></td>
            </tr>
            <tr>
                <td>Write</td>
                <td><Checkbox ref={owner2} name={'owner2'}/></td>
                <td><Checkbox ref={group2} name={'group2'}/></td>
                <td><Checkbox ref={other2} name={'other2'}/></td>
            </tr>
            <tr>
                <td>Execute</td>
                <td><Checkbox ref={owner1} name={'owner1'}/></td>
                <td><Checkbox ref={group1} name={'group1'}/></td>
                <td><Checkbox ref={other1} name={'other1'}/></td>
            </tr>
            </tbody>
        </table>
    </ModuleLayout>
}
