import React, {lazy, Suspense} from 'react'
import ReactModuleContainer from "@/Components/Modules/ReactModuleContainer.jsx";


export default function View ({ module }) {

  const PDFDeletePageLazyView = lazy(() => import('@/Pages/CustomViews/_ext_pdf_delete_page/LazyView.jsx'))
  return <ReactModuleContainer module={module}>
    <Suspense fallback={() => {}}>
      <PDFDeletePageLazyView/>
    </Suspense>
  </ReactModuleContainer>
}
