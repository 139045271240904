import React from 'react'

export default function Checkbox ({ className, name, label, subLabel, checked, handleChange, disabled }) {
  return (
    <label className={'whitespace-nowrap select-none'
      + (className ? ' ' + className : '')
      + (disabled ? ' text-neutral-400 hover:bg-transparent' : ' ')}>

      <input
        type="checkbox"
        name={name}
        checked={checked}
        disabled={disabled}
        className={'mr-2 rounded border-gray-300 text-indigo-600 shadow-sm '
          + ' disabled:bg-neutral-200 disabled:text-white disabled:hover:bg-neutral-200 '
          + ' focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'}
        onChange={handleChange}
      />
      {label}
      {subLabel ? <p className={'text-xs'}>{subLabel}</p> : ''}
    </label>
  )
}
