import Module from '@/Components/Modules/Module'
import {usePage} from "@inertiajs/react";
import {DiscussionEmbed} from "disqus-react";
import React from "react";

export default function Single({className, module, fn, ...props}) {
    return module ? <><Module key={module.name}
                               initialSize={'full'}
                               className={className}
                               fromObject={module}
                               name={module.name}
                               _options={{showTitle: !props.showTitle, showAds: props.showAds}}/>
            {/*<DiscussionEmbed shortname={'Discuss'} config={{*/}
            {/*    url: route('module.single',module.slug),*/}
            {/*    identifier: module.id,*/}
            {/*    title: module.title,*/}
            {/*}}/>*/}
        </>
        : <>Error loading module.</>
}
