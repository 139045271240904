import LeftSideButton from '@/Components/Sidebar/Elements/LeftSideButton'
import { faBarsStaggered } from '@fortawesome/free-solid-svg-icons'

export default function SidebarToggle ({
    className,
    notShownClassName,
    fn,
    index = 0,
    showOnOpen = true,
    showOnClose = true,
}) {

    return <>
        {fn?.sidebar && ((showOnOpen && fn.sidebar.sidebarOpen) || (showOnClose && !fn.sidebar.sidebarOpen))
            ? <LeftSideButton fn={fn} className={className}
                              title={'Toggle Sidebar'}
                              index={index}
                              notShownClassName={notShownClassName}
                              onClick={fn.sidebar.toggleSidebarOpen} icon={faBarsStaggered}>

            </LeftSideButton> : <></>
        }
    </>
}
