import React, { useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'

export default function FormEditor ({ label, id, value, name, onChange, placeholder }) {
    const editorRef = useRef(null)
    const focusOn = () => {
        editorRef.current.focus()
    }
    return <>
        {label ? <label onClick={focusOn} className={'cursor-pointer text-md select-none mb-1 block'}>{label}</label> : <></>}
        <Editor
            tinymceScriptSrc={'/tinymce/tinymce.min.js'}
            onInit={(evt, editor) => editorRef.current = editor}
            value={value}
            onEditorChange={onChange}
            textareaName={name}
            init={{
                height: 500,
                menubar: true,
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount',
                ],
                toolbar: 'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                skin: 'oxide-dark',
                content_css: 'dark',
                placeholder: placeholder,
            }}
        /></>

}
