import Module from '@/Components/Modules/Module'

export default function Playground (props, { title, module }) {

  const RenderModulesCollection = function () {

    return Array.from([
      'strlen',
      'strpos',
      'm3u8_extractor',
      'links_from_url',
    ]).map((module, i) => {

      let _size = 'thumb'
      return (
        <div key={i}><Module className={'col-span-1'} name={module} initialSize={_size}/></div>
      )
    })

  }

  return <>

    <div className={'text-gray-700 text-sm py-10'}>
      <div className={'flex flex-grow-0 flex-wrap items-center justify-center content-center'}>
        <div>
          <h2 className={'text-center font-bold text-2xl mb-5'}>Search for Modules here...</h2>
          <div className={'w-full flex'}>

            <input className={'border rounded rounded-tr-none rounded-br-none border-gray-300 w-10/12'}
                   type={'text'}
                   placeholder={'Search for modules'}/>

            <button
              className={'w-2/12 text-center uppercase font-bold text-xs  bg-gray-900 bg-opacity-90 text-white'
                + ' ring-orange-900 rounded rounded-tl-none rounded-bl-none ring-1'}>Add
            </button>

          </div>
          <div>
            <Module name={'youtube_dl'} initialSize={'full'}/>
          </div>
        </div>
      </div>


    </div>

    <div className={'flex'}>
    </div>
  </>
}
