import PageContainer from '@/Components/PageContainer.jsx'
import ArticleContainer from '@/Components/ArticleContainer.jsx'

export default function Privacy ({ title }) {
    return <ArticleContainer className={'text-sm'} title={title}>
        <p>Effective Date: August 26, 2024</p>

        <h2>Information We Collect</h2>
        <p>We collect several different types of information for various purposes to improve our Service to you.</p>

        <h3>Personal Data</h3>
        <p>While we primarily focus on user input for functionalities, we may collect some personal data in certain situations. This may include:</p>
        <ul>
            <li>Email address (if you choose to contact us directly)</li>
            <li>User input within the website's modules (depending on the specific module)</li>
        </ul>

        <h3>Non-Personal Data</h3>
        <p>We also collect non-personal data that does not directly identify you. This data may include:</p>
        <ul>
            <li>Usage data: We may collect information about your activity on the Service, such as the pages you visit, the time you spend on the pages, and the tools you use.</li>
            <li>Device data: We may collect information about the device you use to access the Service, such as the type of device, operating system, browser, IP address, and device
                identifiers.
            </li>
            <li>Log data: When you use the Service, we may automatically collect information in our server logs. This information may include your IP address, browser type, operating system,
                the referring website, the pages you visit on our Service, the date and time of your visit, and other information.
            </li>
        </ul>

        <h2>Google Analytics and Google Ads</h2>
        <p>We use Google Analytics, a web analytics service offered by Google LLC ("Google"). Google Analytics uses cookies, which are text files placed on your browser, to help us analyze
            how
            users interact with the Service. The information generated by the cookie about your use of the Service (including your IP address) will be transmitted to and stored by Google on
            servers in the United States. Google
            will use this information for the purpose of evaluating your use of the Service, compiling reports on website activity for website operators, and providing other services
            relating
            to website activity
            and internet usage.</p>

        <p>We may also use Google Ads, an online advertising service provided by Google. Google Ads uses cookies to track users across the web in order to deliver targeted advertising.</p>

        <p>You can opt-out of Google Analytics by visiting the Google Analytics Opt-out Browser Add-on: <a
            href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.</p>


        <p>For more information on how Google uses data, please refer to Google's Privacy Policy: <a
            href="https://policies.google.com/privacy?hl=en-US">https://policies.google.com/privacy?hl=en-US</a>.</p>


    </ArticleContainer>
}
