import { useForm } from '@inertiajs/react'
import { lazy, useEffect, useRef } from 'react'
import HR from '@/Components/HR.jsx'

const Tone = lazy(() => {
  import('tone')
})
export default function View ({ className, children }) {
  const synth = new Tone.Player()
  const { data, setData } = useForm({
    sound: {
      result: null,
      array: null,
      binaryString: null,
    },
  })
  const audioRef = useRef(null)
  const waveformRef = useRef(null)
  const wfPlayerRef = useRef(null)

  useEffect(e => {
    if (!wfPlayerRef.current) {

    }
  }, [data])

  const readFile = (file) => {
    let reader = new FileReader()
    reader.onload = readSuccess

    function readSuccess (evt) {
      let arrayBuffer = evt.target.result,
        array = new Uint8Array(arrayBuffer),
        binaryString = String.fromCharCode.apply(null, array)

      setData('sound', {
        result: arrayBuffer,
        array: array,
        binaryString: binaryString,
      })
    }

    reader.readAsDataURL(file)
  }

  return <>
    <form className={'my-5'}>
      <h2>Choose a file to edit</h2>
      <small className={'w-full block'}>This form doesn't actually upload any files, it only attaches the file to your browser,
        and the browser will take care of file editing.</small>
      <HR/>
      <input type={'file'} onChange={e => readFile(e.target.files[0])} name={'file'}/>
    </form>

    <div ref={waveformRef} id="waveform" className={'w-full h-[300px]'}></div>
    <audio id={'audio'} ref={audioRef} src={data.sound.result} controls={true} className={'w-full'}/>
  </>
}