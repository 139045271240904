import React, { useEffect } from 'react'

export default function AdsComponent ({ dataAdSlot }) {

  useEffect(() => {

    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({})
    } catch (e) {

    }

  }, [])

  return <>
    <ins className="adsbygoogle block w-full"
         data-ad-client="ca-pub-0261274621380053"
         data-ad-slot={dataAdSlot}
         data-ad-format="auto"
         data-full-width-responsive="true"></ins>
  </>
}